import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import io from 'socket.io-client'

import Api from '../../services/api'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import { UserContext } from '../../contexts/UserContext';
import { ModalAlert } from '../../components/ModalAlert'

import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TaskIcon from '@mui/icons-material/Task';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';




export const MainHeader = () => {

  const navigate = useNavigate();

  const { state: user } = useContext(UserContext)
  const { dispatch } = useContext(UserContext);

  const [modalAlert, setModalAlert] = useState('')
  const [modalAlertColor, setModalAlertColor] = useState('')
  const [modal, setModal] = useState(false)
  const [state, setState] = useState(false)


  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([])
  const [tokens, setTokens] = useState([])

  const messageEl = useRef('Chat');


  const socket =  io('http://suporte.joyit.com.br:42003', {
    autoConnect: false,
    query: {id: user.id},

})

  const LogoffClick = () => {
    localStorage.removeItem('SessionID')
    navigate('/Login')
  }

  const PermClick = (ContactTypeSelected) => {
    setModalAlertColor('#ffb400')
    switch (ContactTypeSelected) {
      case 'legal':

        setModalAlert(`<p><span style="font-size:16px"><strong>Representante Legal</strong></span></p><hr />
          <p><span style="font-size:14px" <p> Sócio Administrador responsável pela assinatura de contratos e termos de contratação;</p>
          <p><strong>Pode ser indicado mais de uma pessoa, em caso de empresas com mais de um sócio administrador.</strong></p>
          `);
        return
      case 'manager':
        setModalAlert(`
        <p><span style="font-size:16px"><strong>Gestor do Contrato </strong></span></p><hr />
        <ul>
          <li>Solicitar a inclusão e a alteracão da pessoa de contato Administrativo e contato Técnico;</li>
          <li>Solicitar substituição do contato do Gestor do Contrato; (*)</li>
          <li>Solicitar contratação, alteração e exclusão de serviços; (*)</li>
          <li>Tratar dos assuntos financeiros.</li>
        </ul>
        <p><em><span style="font-size:11px">(*) Com efetivação após a assinatura do novo Termo de Contratação pelo Representante Legal</em>.</p>
        <p><strong>Pode ser indicado até 1(uma) pessoa.</strong></p>
        `)
        return
      case 'tec':
        setModalAlert(`
          <p><span style="font-size:16px"><strong>Contatos Técnicos</strong></span></p><hr />
          <ul>
            <li>Solicitar demandas ( Referente a parte técnica dos serviços contratados, configurações gerais e controle de acesso) via chamados.</li>
          </ul>
          <p><strong>Pode ser indicado até 2(duas) pessoas.</strong></p>
          `)
        return
      case 'adm':
        setModalAlert(`
            <p><span style="font-size:16px"><strong>Contatos Administrativos</strong></span></p><hr />
            <ul>
              <li>Receber as NFes e boletos referentes a pagamento de contrato.</li>
            </ul>
            <p><strong>Pode ser indicado até 1(uma) pessoa.</strong></p>
            `)
        return

    }
  }

  const ModalAlertClick = () => {
    setModalAlert('')
  }

  const ModalClick = () => {
    setModal(true)

}

const sendNotification = async (notification) => { 
  let send = await Api.sendTokens({
      "to": tokens,
      "sound": "default",
      "title": 'eSuporte | ' + user.name + ' enviou:',
      "body": notification   
  })
  if (send.data) {
      
  } else {
    //  alert("Dados não encotrados")
  }
}

const ButtonClick = () => {
  if (message.trim()) {
  socket.open()             
  socket.emit('chat.message', {
      ID: user.id,
      id: user.name,
      at: new Date().toLocaleString(),
      agentId: '',
      message
  })
  setMessage('')
  sendNotification(message)
  ScrollToDown()
}
}

const getChatTokens = async () => {
  try { let res = await Api.getChatTokens('JOY')
           const tokenGet = res.map((obj) => {
           return obj.token
           })
           setTokens(tokenGet)
  } catch(err){
    //  alert("Dados não encotrados")
  }
  

}
 document.onkeydown = function(e) {
  if(e.key === 'Enter') {
      ButtonClick()
  }
}


const ScrollToDown = () => {
  messageEl.current.addEventListener('DOMNodeInserted', event => {
    const { currentTarget: target } = event;
    target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  })
}


useEffect(()=>{
  const interval = setInterval(() => {
    setState(state === true ? false : true)

  }, 5000)
}, []);


useEffect(() => {
  socket.open()             
  socket.emit('chat.message', {
      ID: user.id,
      open: 'true'
  })
  socket.close()
            
}, [state])

useEffect(() => {
  getChatTokens()
}, [])

useEffect(() => {
  const sendNewMessage = newMessage =>
  setMessages(newMessage)
  socket.open()             
  socket.on('chat.message', sendNewMessage)
  return () => {
        socket.off();
}
}, [messages, state])

console.log(messages)





 
  return (
    <C.Container>
        <C.IconArea onClick={ModalClick} style={{cursor: 'pointer', borderBottom: messages.length > 0 ? '2px solid #9FE080' : '2px solid #E95839', padding: '4px 5px', width: '70px'}}>
          <ChatBubbleRoundedIcon  style={{fontSize: '22px'}} />
          Chat
        </C.IconArea>
        <C.ContactTypeArea>
                  <C.ContactTypeIconArea onClick={() => PermClick('legal')} >
                            <AccountCircleIcon   style={{color: user.legal === '1' ? '#E0E0E0' : '#aaa', fontSize: '32px'}}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados NOVOS"
                            />
                            <C.ContactTypeIcon style={{ backgroundColor: user.legal === '1' ? '#BDF5D6' : '#aaa' }}>R</C.ContactTypeIcon>
                  </C.ContactTypeIconArea>
                  <C.ContactTypeIconArea onClick={() => PermClick('manager')} >
                            <AccountCircleIcon   style={{color: user.manager === '1' ? '#E0E0E0' : '#aaa', fontSize: '32px'}}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados NOVOS"
                            />
                            <C.ContactTypeIcon style={{ backgroundColor: user.manager === '1' ? '#BDF5D6' : '#aaa' }}>G</C.ContactTypeIcon>
                  </C.ContactTypeIconArea>
                  <C.ContactTypeIconArea onClick={() => PermClick('tec')} >
                            <AccountCircleIcon   style={{color: user.tec === '1' ? '#E0E0E0' : '#aaa', fontSize: '32px'}}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados NOVOS"
                            />
                            <C.ContactTypeIcon style={{ backgroundColor: user.tec === '1' ? '#BDF5D6' : '#aaa' }}>T</C.ContactTypeIcon>
                  </C.ContactTypeIconArea>
                  <C.ContactTypeIconArea onClick={() => PermClick('adm')} >
                            <AccountCircleIcon   style={{color: user.adm === '1' ? '#E0E0E0' : '#aaa', fontSize: '32px'}}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados NOVOS"
                            />
                            <C.ContactTypeIcon style={{ backgroundColor: user.adm === '1' ? '#BDF5D6' : '#aaa' }}>A</C.ContactTypeIcon>
                  </C.ContactTypeIconArea>                                  
                  </C.ContactTypeArea>

      <C.UserArea>
        <BusinessIcon fontSize="large" />
        <C.UserInfoText>{user.company}</C.UserInfoText>
      </C.UserArea>
      <C.UserArea>
        <AccountCircleIcon fontSize="large" />
        <C.UserInfoText>{user.id} | {user.name}</C.UserInfoText>
      </C.UserArea>

      <ExitToAppIcon fontSize="large" style={{ cursor: 'pointer', marginRight: '15px' }} onClick={LogoffClick} />
      
      {modalAlert !== '' &&
        <ModalAlert modalAlert={modalAlert}ß
          setModalAlertClick={ModalAlertClick}
          colorModalAlert={modalAlertColor}
          modalTitle={'Tipos de Contato'}
        />
      }
    {modal && messages.length > 0 &&
    <M.ModalContainer>
      <M.ModalContentSmall style={{backgroundColor: '#ffb400', height: '550px', width: '765px'}}>
      <G.BoxHeader style={{backgroundColor: 'rgba(52, 52, 52, 0.1)', width: '90%', height: '30px', border: 'none',
                           margin: '20px 0px 10px 0px', justifyContent: 'space-between', color: '#000'}}>
      <G.SmallTitle> <TaskIcon/><strong>{messages[0]?.ticketNumber} - {messages[0]?.ticketTitle}</strong> </G.SmallTitle><CloseIcon onClick={ () => {setModal(false)}}/>
      </G.BoxHeader>     
      <G.BoxBodyScroll ref={messageEl} style={{backgroundColor: 'rgba(52, 52, 52, 0.1)', width: '90%', height: '65%', border: 'none', margin: '5px', 
                        flexDirection: 'column', alignItems: 'end', justifyContent: 'start', overflowX: 'none', overflowY: 'auto'}}>
      {messages.map((m, index)=>(

        <G.OptionsArea style={{width: '100%', justifyContent: m.id === user.name ? 'flex-end' : 'flex-start'}}>
        <G.InfoArea  key={index} style={{marginLeft: '10px', backgroundColor: m.id === user.name ? '#2F4F4F' : 'gray', maxWidth: '50%', padding: '10px', justifyContent: 'start', flexDirection: 'column', alignItems: 'start'}}>
          <G.DistacInfoText style={{fontSize: 12, color: '#00FA9A'}}>{m.id}</G.DistacInfoText>
          <G.Text style={{fontSize: 13}} >{m.message} {m.status === 'close' ? setMessages([]) : ''}</G.Text> 
          <G.Text style={{fontStyle: 'italic', fontSize: 11, color: '#D1D1D1'}}>{m.at}</G.Text>
        </G.InfoArea>
        </G.OptionsArea>
        ))} 

      </G.BoxBodyScroll>
      <G.BoxFooter style={{ width: '90%', height: '40px', border: 'none', margin: '0px', flexDirection: 'row'}}>
        <G.Input style={{width: '80%'}}
        value={message}
        onChange={e => setMessage(e.target.value)}
        />
        <PlayCircleIcon onClick={ButtonClick} style={{padding: '10px', backgroundColor: 'rgba(52, 52, 52, 0.1)', fontSize: '42px', borderRadius: '50%', marginLeft: '10px'}}/>
      </G.BoxFooter>
      </M.ModalContentSmall>
    </M.ModalContainer>
    }
    </C.Container>
  );
}
