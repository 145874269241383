import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ReactECharts from 'echarts-for-react';

import * as G from '../../styles/global'


import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import HomeIcon from '@mui/icons-material/Home';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import TaskIcon from '@mui/icons-material/Task';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Options } from '../../container/SideBar/styled';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CloudIcon from '@mui/icons-material/Cloud';
import MessageIcon from '@mui/icons-material/Message';
import StorageIcon from '@mui/icons-material/Storage';
import NewspaperIcon from '@mui/icons-material/Newspaper';


export const Dashboard = () => {
    const navigate = useNavigate();

    const { state: user } = useContext(UserContext)

    var now = new Date()

    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([])
    const [switchButton, setSwitchButton] = useState(false)
    const [state, setState] = useState(false)
    const [emptyWait, setEmptyWait] = useState([])
    const [emptySche, setEmptySche] = useState([])
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [showReply, setShowReply] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState('')
    const [tickets, setTickets] = useState([]);
    const [ticketsSum, setTicketsSum] = useState([]);
    const [ticketsArchive, setTicketsArchive] = useState([]);
    const [ticketsSumArchive, setTicketsSumArchive] = useState([]);




    const option3 = {
      xAxis: {
        type: 'category',
        data: ['2019', '2020', '2021', '2022', '2023']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        { 
          itemStyle: {
            color: '#1E90FF',
            borderRadius: 5,
            borderWidth: 5,
            borderColor: '#fff'
          },
          data: ticketsArchive,
          type: 'bar',
          label: {
            show: true,
            position: 'inside'
          },
        }
      ]
    };

    const option2 = {
      tooltip: { trigger: 'item'},
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Chamados Criados',
          type: 'pie',
          radius: ['30%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 5
          },
          label: {
            show: false,
            position: 'inside'
          },

          data: tickets
        }
      ]
    };

    const GetNews = async () => {

      let res = await Api.getNews();
      setNews(res)

    }

    const getCustomerTickets = async () => {

      let [close, open, novo, pendding] = await  Promise.all([Api.getTicketInfoData('3', user.id), Api.getTicketInfoData('2', user.id),
                                                              Api.getTicketInfoData('1', user.id), Api.getTicketInfoData('4', user.id) ]) ;
          let ticketStates = ([ {'name': 'Novos', 'value': novo[0].ticket === 0 ? null : novo[0].ticket},
                                {'name': 'Abertos', 'value': open[0].ticket === 0 ? null : open[0].ticket},
                                {'name': 'Pendentes', 'value': pendding[0].ticket === 0 ? null : pendding[0].ticket},
                                {'name': 'Fechados', 'value': close[0].ticket === 0 ? null : close[0].ticket},
                                                                         
          ])
          setTicketsSum(close[0].ticket + pendding[0].ticket + novo[0].ticket + open[0].ticket) 
          setTickets(ticketStates)

        
          let [year2019, year2020, year2021, year2022, year2023] = await  Promise.all([Api.getTicketInfoDataArchive('2019', user.id), 
                                                                                       Api.getTicketInfoDataArchive('2020', user.id),
                                                                                       Api.getTicketInfoDataArchive('2021', user.id), 
                                                                                       Api.getTicketInfoDataArchive('2022', user.id),
                                                                                       Api.getTicketInfoDataArchive('2023', user.id)
                                                                          ]) ;
            let ticketArchiveStates = ([ { 'value': year2019[0].ticket === 0 ? null : year2019[0].ticket},
                                        {'value': year2020[0].ticket === 0 ? null : year2020[0].ticket},
                                        {'value': year2021[0].ticket === 0 ? null : year2021[0].ticket},
                                        {'value': year2022[0].ticket === 0 ? null : year2022[0].ticket},
                                        {'value': year2023[0].ticket === 0 ? null : year2023[0].ticket},
                                
            ])
            setTicketsSumArchive(year2019[0].ticket + year2020[0] .ticket+ year2021[0] .ticket + year2022[0] .ticket) 
            setTicketsArchive(ticketArchiveStates)
    }

    const GetTicketsV2 = async () => {
      let res = await Api.getTicketsV2(user.id, '14');
      setEmptySche(res)
      let res2 = await Api.getTicketsV2(user.id, '11');
      setEmptyWait(res2)
    }

    const TicketClick = async (TicketID) => {
      navigate(`/Tickets?TicketID=${TicketID}`)
    }



    useEffect(()=>{
      const interval = setInterval(() => {
        setState(state === true ? false : true)
        console.log('mudou')
      }, 60000)
    }, []);


    useEffect(() => {
      GetTicketsV2();
    }, [news, state])

    useEffect(() => {
      getCustomerTickets();
    }, [news, state])

    useEffect(() => {
      GetNews();
    }, [state])




    // console.log(news)







    return (
      <G.Container>
      <G.Header>
        <G.HeaderTitleArea>
          <HomeIcon fontSize='large' style={{ marginRight: "7px" }} />
          <G.MainTitle>| Dashboard</G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header>
      <G.Body style={{flexDirection: 'row', flexWrap: "wrap"}}>
        <G.Card style={{width: "530px", height: '380px', backgroundColor: '#f9f9f9'}}>
          <G.CardHeader >
            <PendingOutlinedIcon style={{fontSize: '30px', color: '#777', marginRight: '5px'}}/>
            <G.SmallTitle>Chamados Aguardando Resposta</G.SmallTitle>
          </G.CardHeader>
          <G.CardBodyScroll style={{flexDirection: 'column' }}>
            {emptyWait.length === 0 &&
            <G.OptionsArea style={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
            <CheckCircleOutlineIcon style={{fontSize: '140px', color: '#ccc'}}/>
            </G.OptionsArea>
            }
            {emptyWait.map((item, key) => (
            <G.OptionsArea key={key} style={{}}  >
             <G.OptionsArea style={{alignItems: 'start', backgroundColor: '#fff', padding: '10px', width: '95%', border: '1px solid #d0d0d0', borderRadius: '5px', margin: '5px', flexDirection: 'column' }} >

             <G.InfoArea style={{borderBottom: '1px solid #d0d0d0', padding: '5px', width: '95%', justifyContent: 'space-between' }}>
                <G.DistacInfoText  >
                {item.tn} - {item.title} 
                </G.DistacInfoText>
                <OpenInNewIcon onClick={() => TicketClick(item.id)} style={{cursor: 'pointer'}}/>
              </G.InfoArea>
              <G.DistacInfoText>
              </G.DistacInfoText>
              <G.InfoText  style={{padding: '10px', flexDirection: 'Column', alignItems: 'start'}}>
               <strong>Última interação: </strong>  { item.article[item.article.length - 1].a_body.length > 210 ? item.article[item.article.length - 1].a_body.substring(0,210)+' ...' : item.article[item.article.length - 1].a_body }
                </G.InfoText>
                <G.InfoArea style={{ backgroundColor: '#f9f9f9', marginLeft: '5px', padding: '10px 7px', width: '75%', justifyContent: 'start', borderLeft: '5px solid #2E8B57', fontSize: '11px', borderRadius: '3px' }}>
                <G.InfoText  >
                <TimerOutlinedIcon style={{fontSize: '24px', color: '#777', marginRight: '5px'}}/>
                Aguardando resposta desde:
                </G.InfoText>
                <G.InfoText style={{marginLeft: '10px'}} >
                <strong>{new Date(item.change_time).toLocaleString()}</strong>
                </G.InfoText>
              </G.InfoArea>
              </G.OptionsArea>
            </G.OptionsArea>
            ))}

          </G.CardBodyScroll>
          <G.OptionsArea>
            </G.OptionsArea>
        </G.Card>
        <G.Card style={{}}>
        <G.CardHeader >
            <TaskIcon style={{fontSize: '30px', color: '#777', marginRight: '5px'}}/>
            <G.SmallTitle>Chamados</G.SmallTitle>
          </G.CardHeader>
          <ReactECharts option={option2}/>
          <G.CardFooter style={{ background: '#f5f5f5', marginBottom: '0px'}}>
            <G.DistacInfoText>
            Total de chamados: {ticketsSum}
            </G.DistacInfoText>
          </G.CardFooter>
        </G.Card>
        <G.Card style={{width: "530px", height: '380px', backgroundColor: '#f9f9f9'}}>
          <G.CardHeader >
          <EventNoteOutlinedIcon style={{fontSize: '30px', color: '#777', marginRight: '5px'}}/>
            <G.SmallTitle>Chamados Agendados</G.SmallTitle>
          </G.CardHeader>
          <G.CardBodyScroll style={{flexDirection: 'column' }}>
          {emptySche.length === 0 &&
            <G.OptionsArea style={{justifyContent: 'center', alignItems: 'center', height: '300px'}}>
            <CheckCircleOutlineIcon style={{fontSize: '140px', color: '#ccc'}}/>
            </G.OptionsArea>
            }

            {emptySche.map((item, key) => (
            <G.OptionsArea key={key}   >
             <G.OptionsArea style={{alignItems: 'start', backgroundColor: '#fff', padding: '10px', width: '95%', border: '1px solid #d0d0d0', borderRadius: '5px', margin: '5px', flexDirection: 'column' }} >

             <G.InfoArea style={{borderBottom: '1px solid #d0d0d0', padding: '5px', width: '95%', justifyContent: 'space-between' }}>
                <G.DistacInfoText  >
                {item.tn} - {item.title} 
                </G.DistacInfoText>
                <OpenInNewIcon onClick={() => TicketClick(item.id)} style={{cursor: 'pointer'}}/>
              </G.InfoArea>
              <G.DistacInfoText>
              </G.DistacInfoText>
              <G.InfoText  style={{padding: '10px', flexDirection: 'Column', alignItems: 'start'}}>
               <strong>Última interação: </strong>  { item.article[item.article.length - 1].a_body.length > 210 ? item.article[item.article.length - 1].a_body.substring(0,210)+' ...' : item.article[item.article.length - 1].a_body }
                </G.InfoText>
                <G.InfoArea style={{ backgroundColor: '#f9f9f9', marginLeft: '5px', padding: '10px 7px', width: '65%', justifyContent: 'start', borderLeft: new Date(item.start_time) < now ? ' 5px solid red' : '5px solid #2E8B57', fontSize: '11px', borderRadius: '3px' }}>
                <G.InfoText  >
                <EventAvailableOutlinedIcon style={{fontSize: '24px', color: '#777', marginRight: '5px'}}/>
                Agendado para:
                </G.InfoText>
                <G.InfoText style={{marginLeft: '10px'}} >
                <strong>{new Date(item.start_time).toLocaleString()}</strong>
                </G.InfoText>
              </G.InfoArea>
              </G.OptionsArea>

            </G.OptionsArea>
            ))}
          </G.CardBodyScroll>

        </G.Card>
        <G.Card style={{}}>
        <G.CardHeader >
          <NewspaperIcon style={{fontSize: '30px', color: '#777', marginRight: '5px'}}/>
            <G.SmallTitle>Notícias</G.SmallTitle>
          </G.CardHeader>
          <G.CardBodyScroll style={{ flexDirection: 'column', background: '#f9f9f9', marginBottom: '0px', height: '300px'}}>
          {news.map((item, key) => (
            <G.OptionsArea key={key} style={{alignItems: 'start', backgroundColor: '#fff', padding: '10px', width: '95%', border: '1px solid #d0d0d0', borderRadius: '5px', margin: '5px', flexDirection: 'column' }}>
                <G.InfoArea style={{borderBottom: '1px solid #d0d0d0', padding: '5px', width: '95%', justifyContent: 'space-between', marginBottom: '10px' }}>               
                <G.DistacInfoText  style={{width: '300px'}}> {item.type ===  'CLOUD' ? <CloudIcon style={{marginRight: '5px', color: '#1E90FF'}}/> : 
                                    item.type ===  'ATENDIMENTO' ? <MessageIcon style={{marginRight: '5px', color: '#E95839'}}/>:
                                    <StorageIcon style={{marginRight: '5px', color: '#FFb400'}}/>}{item.subject}</G.DistacInfoText>
                <G.DistacInfoText>{item.date}</G.DistacInfoText>
               </G.InfoArea>
               <G.InfoArea>
                 <G.InfoText>{item.content}</G.InfoText>
               </G.InfoArea>
            </G.OptionsArea>
          ))}
          </G.CardBodyScroll>
        </G.Card>
        <G.Card style={{}}>
        <G.CardHeader >
          <ArchiveIcon style={{fontSize: '30px', color: '#777', marginRight: '5px'}}/>
            <G.SmallTitle>Chamados Arquivados</G.SmallTitle>
          </G.CardHeader>
          <ReactECharts option={option3}/>
          <G.CardFooter style={{ background: '#f5f5f5', marginBottom: '0px'}}>
            <G.DistacInfoText>
            Total de chamados arquivados: {ticketsSumArchive}
            </G.DistacInfoText>
          </G.CardFooter>
        </G.Card>
      </G.Body>

      <G.Footer style={{ fontSize: '12px' }}>
        <p>Copyright <CopyrightIcon style={{ fontSize: '12px' }} /> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda.</p>
      </G.Footer>

      </G.Container>

    );
}
