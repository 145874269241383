import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import TaskIcon from '@mui/icons-material/Task';
import CheckIcon from '@mui/icons-material/Check';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningIcon from '@mui/icons-material/Warning';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import CopyrightIcon from '@mui/icons-material/Copyright';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
// Icon do DataTable
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const Contacts = () => {
    const navigate = useNavigate();

    const { state: user } = useContext(UserContext)

    const [list, setList] = useState([]);
    const [modalType, setModalType] = useState()
    const [alertError, setAlertError] = useState('')
    const [state, setState] = useState('')
    const [modal, setModal] = useState(false)


    const [selectedContact, setSelectedContact] = useState()
    const [nameField, setNameField] = useState('')
    const [idField, setIdField] = useState('')
    const [lastNameField, setLastNameField] = useState('')
    const [loginField, setLoginField] = useState('')
    const [mailField, setMailField] = useState('')
    const [phoneField, setPhoneField] = useState('')
    const [legalField, setLegalField] = useState(false)
    const [managerField, setManagerField] = useState(false)
    const [tecField, setTecField] = useState(false)
    const [admField, setAdmField] = useState(false)


    // States do DataTable - Início
    const [filteredList, setFilteredList] = useState([])
    const [order, setOrder] = useState('')
    const [activeOrder, setActiveOrder] = useState('')
    // States do DataTable - Fim
    
    // Function do DataTable - Início
    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase()
      let newArray = list.filter((data) => {
          return data.first_name.toLowerCase().search(value) !== -1 || 
                data.last_name.toLowerCase().search(value) !== -1 ||
                data.login.toLowerCase().search(value) !== -1 ||
                data.email.toLowerCase().search(value) !== -1
      })
      setFilteredList(newArray);
    }

    const OrderClick = (field) => {
      setActiveOrder(field)
      let result = filteredList.sort(function(a, b){
        if (order === 'asc'){
          return a[field] < b[field] ? -1 : true
        } else {
          return a[field] > b[field] ? -1 : true
        }
      })
      order === 'asc' ? setOrder('desc') : setOrder('asc')
      setFilteredList(result)
    }
    // Function do DataTable - Fim

    const AddClick = () => {
        setModalType(1)
        setModal(true)
    }  

      const EditClick = (id) => {
        setIdField(list[id].id)
        setNameField(list[id].first_name)
        setLastNameField(list[id].last_name)
        setLoginField(list[id].login)
        setMailField(list[id].email)
        setPhoneField(list[id].mobile)
        setLegalField(list[id].fork_legal === 1 ? true : false)
        setManagerField(list[id].fork_manager === 1 ? true : false)
        setTecField(list[id].fork_tec === 1 ? true : false)
        setAdmField(list[id].fork_adm === 1 ? true : false)
        setSelectedContact([id])
        setModalType(2)
        setModal(true)
    }

    const CloseModalClick = () => {
      setNameField('')
      setLastNameField('')
      setLoginField('')
      setMailField('')
      setPhoneField('')
      setLegalField(false)
      setManagerField(false)
      setTecField(false)
      setAdmField(false)
      setSelectedContact()
      setAlertError('')
      setModal(false)
    }
    const Validate = () => {
      setLoginField((nameField+'.'+lastNameField).toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
      const countTec = list.filter(item => item.fork_tec === 1).length
      const countAdm = list.filter(item => item.fork_adm === 1).length

      const erroNo = 'Ative pelo menos um tipo de contato para ser associado ao usuário'
      const erroTec = 'O número máximo de 02 contatos TÉCNICOS foi excedido. Desabilite um usuário ou desative a função em um dos usuários ativos'
      const erroAdm = 'O número máximo de 01 contato ADMIN foi excedido. Desabilite um usuário ou desative a função em um dos usuários ativos'
      
      if (!legalField && !managerField && !tecField && !admField ) {
        setAlertError(erroNo)
      }
      
      else if(modalType === 1){
        if(countTec+tecField > 2){
          setAlertError(erroTec)
        }
        else if(countAdm+admField > 1){
          setAlertError(erroAdm)
        }
        else{
          SendContacts()
        }
      } 
      else {
        if(list[selectedContact].fork_tec ==! 1 && tecField){
          if(countTec+tecField > 2){
          setAlertError(erroTec)
        }else{
          SendContacts()
        }}
        else if(list[selectedContact].fork_adm ==! 1 && admField ){
          if(countAdm+admField > 1){
          setAlertError(erroAdm)
        }else{
          SendContacts()
        }} 
        else{
          SendContacts()
        }
      }    
    }

    const SendContacts = async () => {
       let userLogin = (nameField+'.'+lastNameField).toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") 

        const mailSubject = 'Uma nova conta foi criada no eSuporte | Joy'

        const mailBody = `<div style="background-color: #f9f9f9; height: 700px; width: 100%; display: flex; align-items: center; flex: 1; justify-content: center;">
        <div style="padding: 70px; background-color: #333; color: #fff; width: 400px; height: 500px;">
        <div><img style="height: 130px; width: 130px; margin-bottom: 30px;" src="https://static.wixstatic.com/media/8e8cb2_b93ef98c2e0e427183b69b46fb7b6d22~mv2.png/v1/fill/w_114,h_111,al_c,q_85,enc_auto/logo_eSuporte.png" /></div>
        <p><strong>Olá ${nameField}, </strong></p>
        <p>Seja bem vindo ao eSuporte !</p>
        <p>&nbsp;</p>
        <p>Agora você já pode acessar o App.</p>
        <p><a href="http://app.joyit.com.br" target="_blank" title="eSuporte"><span style="font-size: 18px;"><strong><span style="color: #f39c12;">app.joyit.com.br</span></strong></span></a></p>
        <p>&nbsp;</p>
        <p>Acesse o link acima e faça o login com as credenciais a seguir:</p>
        <p><span style="color: #f39c12;">${userLogin}</span></p>
        <p><span style="color: #f39c12;">321@mudar</span></p>
        <p><br /> <strong>Abraços da equipe Joy.</strong></p>
        <p><a href="https://joyit.com.br" target="_blank" title="Joy IT"><span style="color: #fff;">https://joyit.com.br<span></a></p>
        </div>
        </div>
        `
       let res = await Api.addContacts(idField, nameField, lastNameField, userLogin, mailField, phoneField, legalField, managerField, tecField, admField, user.id);
       let res2 = await Api.sendMail(mailField, mailSubject, mailBody)
       CloseModalClick()
       getContacts()
      //  console.log(res)
       console.log(res2)
    }

    const ValidContacts = async (userID, isValid) => {
      let res = await Api.validContacts(isValid, userID);
      getContacts()

   }


    const getContacts = async () => {
      let res = await Api.getContacts(user.id);
      setList(res)
      setFilteredList(res)
    }

    useEffect(()=>{
      getContacts();
      }, [state]);




    return (
        <G.Container>
          <G.Header>
            <G.HeaderTitleArea>
              <ContactEmergencyIcon fontSize='large' style={{ marginRight: "7px" }} />
              <G.MainTitle>| Contatos</G.MainTitle>
            </G.HeaderTitleArea>
          </G.Header>
          <G.Body>
            <G.Box>
              <G.TableHeader style={{ justifyContent: 'space-between' }}>
                <G.SubTitle>Contatos da Empresa</G.SubTitle>
                <G.InfoArea style={{ justifyContent: 'space-between' }}>
                  <SearchIcon />
                  <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
                  {user.manager === '1' &&
                    <G.Button style={{ backgroundColor: '#2E8B57', marginLeft:'100px'}} onClick={AddClick}>Adicionar</G.Button>
                  }
                </G.InfoArea>
              </G.TableHeader>
              <G.TableHeader>
                <G.OptionsArea>
                  <G.FieldArea size={'35px'}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'130px'} onClick={() => OrderClick('first_name')}><G.DistacInfoText style={{  }}>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'first_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'110px'} onClick={() => OrderClick('login')}><G.DistacInfoText>Usuário</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'login') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'160px'}><G.DistacInfoText>Permissões</G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'240px'} onClick={() => OrderClick('email')}><G.DistacInfoText>E-mail</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'email') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'}><G.DistacInfoText>Telefone</G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'80px'}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>
              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px', overflowY: 'scroll'}}>
                {filteredList.map((item, key) => (
                <G.TableArea key={key}>
                  {item.fork_tec !== 2 &&
                  <>
                  <G.FieldArea size={'35px'}><G.Text><C.ContactTypeIconArea style={{ border: item.valid_id === 1 ? '3px solid #2E8B57' : '3px solid #E95839'}}>
                    <AccountCircleIcon style={{fontSize: '27px', color: '#000'}}/>
                  </C.ContactTypeIconArea></G.Text></G.FieldArea>
                  <G.FieldArea size={'130px'}><G.Text>{item.first_name} {item.last_name}</G.Text></G.FieldArea>
                  <G.FieldArea size={'110px'}><G.Text>{item.login}</G.Text></G.FieldArea>
                  <G.FieldArea size={'160px'} style={{ justifyContent: 'space-around', margin: '0px 5px' }} >
                    <C.ContactTypeIconArea style={{border:  item.valid_id === 2 ? '3px solid #999' : item.fork_legal === 1 ? '3px solid #2E8B57' : '3px solid #f9ccc0'}}  >
                       <C.ContactTypeIcon>R</C.ContactTypeIcon>
                    </C.ContactTypeIconArea>
                    <C.ContactTypeIconArea style={{border:  item.valid_id === 2 ? '3px solid #999' : item.fork_manager === 1 ? '3px solid #2E8B57' : '3px solid #f9ccc0'}}>
                      <C.ContactTypeIcon>G</C.ContactTypeIcon>
                    </C.ContactTypeIconArea>
                    <C.ContactTypeIconArea style={{border:  item.valid_id === 2 ? '3px solid #999' : item.fork_tec === 1 ? '3px solid #2E8B57' : '3px solid #f9ccc0'}}>
                      <C.ContactTypeIcon >T</C.ContactTypeIcon>
                    </C.ContactTypeIconArea>
                    <C.ContactTypeIconArea style={{border:  item.valid_id === 2 ? '3px solid #999' : item.fork_adm === 1 ? '3px solid #2E8B57' : '3px solid #f9ccc0'}}>
                      <C.ContactTypeIcon >A</C.ContactTypeIcon>
                    </C.ContactTypeIconArea>  
                  </G.FieldArea>
                  <G.FieldArea size={'240px'}><G.Text>{item.email}</G.Text></G.FieldArea>
                  <G.FieldArea size={'120px'}><G.Text>{item.mobile}</G.Text></G.FieldArea>
                  {user.manager === '1' &&
                  <G.FieldArea size={'80px'} style={{ justifyContent: 'end'}}>
                    {(item.fork_legal ==! 1  && item.fork_manager ==! 1 ) &&
                      <G.IconButton onClick={() => ValidContacts(item.id, item.valid_id === 1 ? 2 : 1)} style={{backgroundColor:  item.valid_id === 1 ? '#E95839' : '#2E8B57' }}> {item.valid_id === 1 ? <BlockIcon fontSize="small" /> :  <CheckIcon fontSize="small" />}</G.IconButton>
                    } {item.valid_id === 1 && 
                      <G.IconButton onClick={() => EditClick(key)} style={{backgroundColor: '#1E90FF'}}><EditIcon fontSize="small" /></G.IconButton>
                    }
                  </G.FieldArea>
                  }
                  </>
                  }
                </G.TableArea>
                ))}
              </G.TableBody>
            </G.Box>
          </G.Body>
          <G.Footer style={{ fontSize: '12px' }}>
            <p>Copyright <CopyrightIcon style={{ fontSize: '12px' }} /> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda.</p>
          </G.Footer>


            {modal !== false &&
                    <C.ModalContainer>
                    <M.ModalContentSmall>
                      <M.ModalHeader style={{ height: '30px',  justifyContent:'space-between', backgroundColor: modalType === 1 ? '#BDF5D6' : '#C8E4FF' }}>
                        <G.HeaderTitleArea>
                        {modalType === 2 ? <EditIcon style={{fontSize: '24px', marginRight: '5px'}}/> : 
                        <AddTaskOutlinedIcon style={{fontSize: '24px', marginRight: '5px'}}/> }
                          <G.SmallTitle>
                          {modalType === 1 ? 'Adicionar Contato' : 'Editar Contato' }
                          </G.SmallTitle>
                        </G.HeaderTitleArea>
                        <G.InfoArea>
                          <HighlightOffIcon onClick={CloseModalClick} style={{color: '#333'}} fontSize="large"/>
                        </G.InfoArea>
                      </M.ModalHeader>
                      <M.ModalBody style={{ width: '500px'}}>
                      <G.BoxBodyScroll style={{ width: '500px', marginLeft: '20px', flexWrap: 'wrap', backgroundColor: '#fff', justifyContent: 'start' }}>
                        <G.FormInputArea style={{width: '90%'}}>
                          <G.FormInputLabel>Nome</G.FormInputLabel> <G.FormInput  autoFocus disabled={selectedContact && filteredList[selectedContact].pw !== null ? true : false} value={nameField} onChange={e => setNameField(e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1).split(' ')[0].toLowerCase())} />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '90%'}}>
                          <G.FormInputLabel>Sobrenome</G.FormInputLabel> <G.FormInput disabled={selectedContact && filteredList[selectedContact].pw !== null ? true : false} value={lastNameField} onChange={e => setLastNameField(e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1).split(' ')[0].toLowerCase())} />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '90%'}}>
                          <G.FormInputLabel>Usuário</G.FormInputLabel> <G.FormInput style={{backgroundColor: '#e0e0e0'}} disabled value={(nameField+'.'+lastNameField).toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")}  />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '90%'}}>
                          <G.FormInputLabel>Email</G.FormInputLabel> <G.FormInput disabled={selectedContact && filteredList[selectedContact].pw !== null ? true : false} value={mailField} onChange={e => setMailField(e.target.value)} />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '90%'}}>
                          <G.FormInputLabel>Telefone</G.FormInputLabel> <G.FormInput disabled={selectedContact && filteredList[selectedContact].pw !== null ? true : false} value={phoneField}
                           maxLength="15" 
                           onChange={e => setPhoneField(e.target.value.replace(/\D/g,'').replace(/(\d{2})(\d)/,"($1) $2").replace(/(\d)(\d{4})$/,"$1-$2"))}  />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '45%'}}>
                          <G.FormInputLabel>Representante Legal</G.FormInputLabel> <G.FormInput  type="checkbox" checked={legalField} disabled  />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '45%'}}>
                          <G.FormInputLabel>Gestor</G.FormInputLabel> <G.FormInput  type="checkbox" checked={managerField} disabled  />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '45%'}}>
                          <G.FormInputLabel>Técnico</G.FormInputLabel> <G.FormInput  type="checkbox" value={tecField} checked={tecField} onChange={e => setTecField(!tecField)}   />                      
                        </G.FormInputArea>
                        <G.FormInputArea style={{width: '45%'}}>
                          <G.FormInputLabel>Admin</G.FormInputLabel> <G.FormInput type="checkbox" value={admField} checked={admField} onChange={e => setAdmField(!admField)}  />                      
                        </G.FormInputArea>
                        </G.BoxBodyScroll>
                      </M.ModalBody>
    
                      <M.ModalFooter style={{ flexDirection: 'row', height: '40px' }}>
                      {alertError !== '' &&
                      <C.Alert style={{marginTop: '-80px'}}><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }}/>{alertError}</C.Alert>
                      }                         
                     <G.Button onClick={Validate} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>

                      </M.ModalFooter>
                    </M.ModalContentSmall>
                  </C.ModalContainer>
            }
        </G.Container>

    );
}
