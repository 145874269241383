const CLIENT_API = 'http://suporte.joyit.com.br:42300';

export default {
    checkTokenCustomer: async () => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/session?token=${token}`)
        const sessionGet = await req.json();
        return sessionGet;
    },

    loginCustomer: async (CustomerUserLogin, Password) => {
        const req = await fetch(`${CLIENT_API}/auth`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email' : CustomerUserLogin,
                'password': Password
            })
        });
        const session = await req.json();
        return session;
    },

    getServices: async () => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/services?token=${token}`)
        const servicesGet = await req.json();
        return servicesGet;
    },

    getMail: async (login) => {
        const req = await fetch(`${CLIENT_API}/renew?login=${login}`)
        const newsGet = await req.json();
        return newsGet;
    },

    getTicketInfoData: async (stateType, joyID) => {
        const req = await fetch(`${CLIENT_API}/ticketInfoData?joyID=${joyID}&stateType=${stateType}`)
        const newsGet = await req.json();
        return newsGet;
    },

    getTicketInfoDataArchive: async (year, joyID) => {
        const req = await fetch(`${CLIENT_API}/ticketInfoDataArchive?joyID=${joyID}&year=${year}`)
        const newsGet = await req.json();
        return newsGet;
    },

    


    // sendMail: async (userMail, token) => {
    //     const req = await fetch(`${CLIENT_API}/sendMail?token=${token}&email=${userMail}`)
    //     const newsGet = await req.json();
    //     return newsGet;
    // }

    sendMail: async (mailDest, mailSubject, mailBody) => {
        const req = await fetch(`${CLIENT_API}/sendMail`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "dest": mailDest,
                    "subject": mailSubject,
                    "body": mailBody,

                }
            )
        });
        const session = await req.json();
        return session;
    },



    renew: async (login, pw) => {
        const req = await fetch(`${CLIENT_API}/renew`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "login": login,
                    "pw": pw

                }
            )
        });
        const session = await req.json();
        return session;
    },




    getNews: async () => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/news?token=${token}`)
        const newsGet = await req.json();
        return newsGet;
    },

    getCustomerTickets: async (StateType, CustomerUserLogin) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/tickets?token=${token}&customerUserLogin=${CustomerUserLogin}&stateType=${StateType}`);
        const ticket = await req.json();
        if (ticket) {
            return ticket
        } else {
            return 'nodata'
        }
    },

    getCustomerArchTickets: async (DateFilter, CustomerUserLogin) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/archTickets?token=${token}&customerUserLogin=${CustomerUserLogin}&dateNewer=${DateFilter}-01-01 00:00:01&dateOlder=${DateFilter}-12-31 00:00:01`);
        const ticket = await req.json();
        if (ticket) {
            return ticket
        } else {
            return 'nodata'
        }


    },

    getChatTokens: async (joyID) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/appInfo?token=${token}&joyID=${joyID}`)
        const tokensGet = await req.json();
        return tokensGet;
    },

    sendTokens: async (messages) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/appNotification?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
               messages
            )
        });
        const session = await req.json();
        return session;
    },

    getCustomerTicketZoom: async (TicketID) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/ticketZoom?token=${token}&ticketID=${TicketID}`);
        const json = await req.json()
        return json ;
    },


    sendArticle: async (TicketID, To, Body, Subject, State) => {
        const token = await localStorage.getItem('SessionID')
        const req = await fetch(`${CLIENT_API}/articles?token=${token}&ticketID=${TicketID}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "State": State,
                },
                "Article": {
                "From": To,
                "ContentType": "text/html; charset=UTF8",
                "CommunicationChannel": "Phone",
                "Subject": Subject,
                "Body": Body,
                "SenderType": "customer"
                }})
        });
        const article = await req.json();
        return article;



    },

    sendArticleImage: async (TicketID, To, Body, Subject, file, fileName, fileType) => {
        const token = await localStorage.getItem('SessionID')
        const req = await fetch(`${CLIENT_API}/articles?token=${token}&ticketID=${TicketID}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "State":"open",
                },
                "Article": {
                "From": To,
                "ContentType": "text/html; charset=UTF8",
                "CommunicationChannel": "Phone",
                "Subject": Subject,
                "Body": Body,
                "SenderType": "customer"
                },
                "Attachment" : {
                    "Content": file,
                    "Filename": fileName,
                    "ContentType": fileType
                }

            })
        });
        const articleImage = await req.json();
        return articleImage;



    },



    openTicket: async (fullSubject, body, service, SLA, userLogin) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/openTicket?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "Title": fullSubject ,
                    "Type":"Unclassified",
                    "Queue":"Triagem",
                    "State":"new",
                    "Service": service,
                    "SLA": SLA,
                    "Priority":"3 normal",
                    "CustomerUser": userLogin
                }
                ,"Article": {
                    "ContentType": "text/html; charset=UTF8",
                    "Subject": fullSubject,
                    "Body": body,
                }
                })
        });
        const ticket = await req.json();
        return ticket;
    },

    openTicketImage: async (fullSubject, body, file, fileName, fileType, service, SLA, userLogin) => {
        const token = await localStorage.getItem('SessionID')
        const req = await fetch(`${CLIENT_API}/openTicket?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "Ticket": {
                    "Title": fullSubject ,
                    "Type":"Unclassified",
                    "Queue":"Triagem",
                    "State":"new",
                    "Service": service,
                    "SLA": SLA,
                    "Priority":"3 normal",
                    "CustomerUser": userLogin
                }
                ,"Article": {
                    "ContentType": "text/html; charset=UTF8",
                    "Subject": fullSubject,
                    "Body": body,
                }
                ,"Attachment" : {
                    "Content": file,
                    "Filename": fileName,
                    "ContentType": fileType
                }

                })
        });
        const ticketImage = await req.json();
        return ticketImage;

    },

    getContacts: async (Customer_id) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/contacts?token=${token}&customer_id=${Customer_id}`)
        const servicesGet = await req.json();        
        return servicesGet;
    },
   

    addContacts: async ( idField, nameField, lastNameField, loginField, mailField, phoneField, legalField, managerField, tecField, admField, userID) => {
        const token = await localStorage.getItem('SessionID')
        const req = await fetch(`${CLIENT_API}/contacts?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {    "id": idField ? idField : null,
                     "login": loginField,
                     "email": mailField,
                     "customer_id": userID,
                     "first_name": nameField,
                     "last_name": lastNameField,
                     "mobile": phoneField,
                     "fork_legal": legalField ? 1 : 0,
                     "fork_manager": managerField ? 1 : 0,
                     "fork_tec": tecField ? 1 : 0,
                     "fork_adm": admField ? 1 : 0,
                    "create_time": new Date().toISOString().slice(0, 19),
                    "change_time": new Date().toISOString().slice(0, 19),
                    "create_by": "1",
                    "change_by": "2",
                    "valid_id": 1,
                }
            )
        });
        const session = await req.json();
        return session;
    },

    validContacts: async (isValid, idField) => {
        const token = await localStorage.getItem('SessionID')
        const req = await fetch(`${CLIENT_API}/contacts?token=${token}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {    "id": idField ,
                    "fork_legal": 0,
                    "fork_manager": 0,
                    "fork_tec": 0,
                    "fork_adm": 0,
                     "create_time": new Date().toISOString().slice(0, 19),
                     "change_time": new Date().toISOString().slice(0, 19),
                     "valid_id": isValid,
                }
            )
        });
        const session = await req.json();
        return session;
    },

    getContracts: async (joyId) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/contracts?token=${token}&id=${joyId}`)
        const servicesGet = await req.json();
        return servicesGet;
    },

    getTerms: async (contractNumber) => {
        const token = await localStorage.getItem('SessionID');
        if (contractNumber !== '') {
        const req = await fetch(`${CLIENT_API}/contract?token=${token}&contract_number=${contractNumber}`)
        const servicesGet = await req.json();
        return servicesGet;
        }
    },

    getTermDetails: async (termId) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/term?token=${token}&term_id=${termId}`)
        const servicesGet = await req.json();
        return servicesGet;
    },


    getTicketsV2: async (joyID, stateID) => {
        const token = await localStorage.getItem('SessionID');
        const req = await fetch(`${CLIENT_API}/getTicketsV2?token=${token}&joyID=${joyID}&stateID=${stateID}`)
        const servicesGet = await req.json();
        return servicesGet;
    },

};
