import React, {useState, useEffect, useContext} from 'react'
import { Outlet, useNavigate } from "react-router-dom";

import { MainHeader } from '../../container/Header';
import { SideBar } from '../../container/SideBar';
import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import * as C from './styled'


export const Layout = () => {

  const navigate = useNavigate()
  const { dispatch } = useContext(UserContext);

  const [loading, setloading] = useState(true)



  useEffect(()=>{
      const checkLogin = async () => {
        const token = await localStorage.getItem('SessionID')
         if(token){
            let res = await Api.checkTokenCustomer()
              if(res.Error){
                navigate('/Login')
              } else {
                let userLogin = res.SessionData.find(x => x.Key === 'UserLogin')
                let userID = res.SessionData.find(x => x.Key === 'CustomerID')
                let userFullName = res.SessionData.find(x => x.Key === 'UserFullname')
                let userCompany = res.SessionData.find(x => x.Key === 'CustomerCompanyName')
                let userEmail = res.SessionData.find(x => x.Key === 'UserEmail')
                let userLegal = res.SessionData.find(x => x.Key === 'UserLegal')
                let userManager = res.SessionData.find(x => x.Key === 'UserManager')
                let userTec = res.SessionData.find(x => x.Key === 'UserTec')
                let userAdm = res.SessionData.find(x => x.Key === 'UserAdm')


              dispatch({
                  type: 'setUser',
                  payload:{
                    user: userLogin.Value,
                    id: userID.Value,
                    name: userFullName.Value,
                    company: userCompany.Value,
                    email: userEmail.Value,
                    legal: userLegal.Value,
                    manager: userManager.Value,
                    tec: userTec.Value,
                    adm: userAdm.Value
                  },
              });
           //   navigate('/Dashboard')
              }


        } else {
          navigate('/Login')
        }
      }
      checkLogin()

    }, [])

    return (
      <C.Container>
            <C.Sider>
                <C.Logo src={"/logo.png"}/>
                <C.MenuHeader>
                </C.MenuHeader>
                <C.MenuBody>
                <SideBar/>
                </C.MenuBody>
            </C.Sider>
            <C.Body>
                <C.Header>
                <MainHeader/>
                </C.Header>
                <C.PageBody>
                  <Outlet />
                </C.PageBody>
            </C.Body>
       </C.Container>

    );
}
