import styled from 'styled-components';


/// GENERAL ///////////////////////////////////////////////////////////////////

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

export const ModalContentSmall = styled.div`
  background-color: #fff;
  height: 550px;
  width: 500px;
  overflow: none;
  border-radius: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;

`;

export const ModalContentLarge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: none;
  max-height: 650px;
  
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 20px;

`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  width: 100%;
  background-color: #fff;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
`;

export const ModalBody = styled.div`
  display: flex;
  margin: 10px 0px;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 0px;
`;

