import { Routes, Route } from "react-router-dom";

import { Layout } from '../container/Layout';
import { Dashboard } from '../pages/Dashboard';
import { Login } from '../pages/Login';
import { Renew } from '../pages/Renew';
import { Tickets } from "../pages/Tickets";
import { Services } from "../pages/Services";
import { Contracts } from "../pages/Contracts";
import { Contacts } from "../pages/Contacts";
import { Archive } from "../pages/Archive";
import { Template } from "../pages/Template";


export const MainRoute = () => {

    return (
            <Routes>
                <Route path="/" element={<Layout/>}>
                <Route path="Dashboard" element={<Dashboard/>}/>
                <Route path="Tickets" element={<Tickets/>}/>
                <Route path="Services" element={<Services/>}/>
                <Route path="Contracts" element={<Contracts/>}/>
                <Route path="Contacts" element={<Contacts/>}/>
                <Route path="Archive" element={<Archive/>}/>
                </Route>
                <Route path="/Login" element={<Login/>}/>
                <Route path="/Renew" element={<Renew/>}/>
                <Route path="/Template" element={<Template/>}/>
            </Routes>
    );
}
