import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import * as C from './styled'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';

import HomeIcon from '@mui/icons-material/Home';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import SellIcon from '@mui/icons-material/Sell';
import TaskIcon from '@mui/icons-material/Task';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudIcon from '@mui/icons-material/Cloud';
import LanIcon from '@mui/icons-material/Lan';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import HelpIcon from '@mui/icons-material/Help';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LabelIcon from '@mui/icons-material/Label';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PendingActionsIcon from '@mui/icons-material/PendingActions';


export const SideBar = () => {
    const navigate = useNavigate();

    const { state:user } = useContext(UserContext)

    const [tokens, setTokens] = useState([])

    const [step, setStep] = useState(1);
    const [modal, setModal] = useState(false)
    const [alert, setAlert] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertIcon, setAlertIcon] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState([])
    const [convertedFile, setConvertedFile] = useState([])

    const [tag, setTag] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [isEmergency, setIsEmergency] = useState('')

    var contentUpper = ((content.length - content.replace(/[A-Z]/g, '').length) / (content.length - 1)) * 100;
    var subjectUpper = ((subject.length - subject.replace(/[A-Z]/g, '').length) / (subject.length - 1)) * 100;

    const service = tag === 'CLOUD' ? 'Cloud' :
                    tag === 'INFRA' ? 'Infraestrutura' :   
                    tag === 'SERVIDOR' ? 'Servidor' : 
                    tag === 'SOLUÇÕES' ? 'Solucoes' : 'Outros'
    const fullSubject = tag + ' | ' + subject

    const MenuItemClick = (destination) => {
        navigate(destination);
    }


    const StepClick = (stepPage, stepAlert) => {
        setStep(stepPage)
        setAlert(stepAlert)
        Validate()
    }



    const Step1Click = (stepPage, stepAlert, selectedTag) => {
        
        setStep(stepPage)
        setAlert(stepAlert)
        setTag(selectedTag)
    }

    const Step5Click = (stepPage, sla) => {
        setStep(stepPage)
        setIsEmergency(sla)
        setAlert(0)
        Convert()
    }


    const ModalClick = () => {
        setModal(true);
    }


    const CloseModalClick = () => {
        setAlertIcon(0)
        setAlertText(0)
        setAlert('')
        setStep(1)
        setModal(false)
        setTag('')
        setSubject('')
        setContent('')
        setSelectedFile([])
    }


    const Convert = async () => {
        
          var reader = new FileReader();
          reader.readAsDataURL(selectedFile);
      
          reader.onload = () => {
            let base64result = reader.result.split(',')[1]
              setConvertedFile(base64result)
          };

        }


    const CallClick = async () => {
        setLoading(true)
        let send = await Api.openTicket('CONTATO | Solicitação de Contato',
                                        'Foi solicitado contato por telefone',
                                        'Outros', 'SUP-R', user.user )
        if (send.ArticleID) {
                    sendNotification(send.TicketNumber, 'CONTATO | Solicitação de Contato', 'Foi solicitado contato por telefone' )
                    setLoading(false)
                    setAlertText('Sua solicitação foi registrada em nosso sistema\n\nO chamado numero: ' + send.TicketNumber + ' foi criado!')
                    setAlertIcon('/ok.svg')
                    setAlert(2)


        } else {
                    sendNotification(send.TicketNumber)
                    setLoading(false)
                    setAlertText('Ocorreu um erro ao abrir o chamado!')
                    setAlertIcon('/close.svg')
                    setAlert(2)

        }
    }

    const Validate = () => {
        if (subjectUpper > 50) {
            setSubject(subject.charAt(0).toUpperCase() + subject.toLowerCase().substring(1))
          }
        
        if (contentUpper > 50) {
        setContent(content.charAt(0).toUpperCase() + content.toLowerCase().substring(1))
        }

        console.log('passou pelo Validete')
    }

    const TicketClick = async () => {
        setAlert(1)
        setLoading(true)

        console.log(fullSubject)

        if (selectedFile.length === 0) 
            {
            let send = await Api.openTicket(fullSubject, content.replace(/(\r\n|\r|\n)/g, '<br>'), service, isEmergency, user.user )
            if (send.ArticleID) {
                sendNotification(send.TicketNumber, fullSubject, content)
                setLoading(false)
                setAlertText('Sua solicitação foi registrada em nosso sistema\n\nO chamado numero: ' + send.TicketNumber + ' foi criado!')
                setAlertIcon('/ok.svg')
                setAlert(2)
            
            } else {

                setLoading(false)
                setAlertText('Ocorreu um erro ao abrir o chamado!')
                setAlertIcon('/close.svg')
                setAlert(2)

            }}
        else {

            let send = await Api.openTicketImage(fullSubject, content.replace(/(\r\n|\r|\n)/g, '<br>'), convertedFile, selectedFile.name, selectedFile.type, service, isEmergency, user.user)
            if (send.ArticleID) {
                sendNotification(send.TicketNumber, fullSubject, content)
                setLoading(false)
                setAlertText('Sua solicitação foi registrada em nosso sistema\n\nO chamado numero: ' + send.TicketNumber + ' foi criado!')
                setAlertIcon('/ok.svg')
                setAlert(2)
            
            } else {

                setLoading(false)
                setAlertText('Ocorreu um erro ao abrir o chamado!')
                setAlertIcon('/close.svg')
                setAlert(2)
            }
        }

    }

    const sendNotification = async (NumberTicket, SubjectTicket, ContentTicket ) => {
       
          let send = await Api.sendTokens({
            "to":  tokens,
            "sound": "default",
            "title": user.name + ' (' + user.id + ') abriu um novo chamado',
            "body":  NumberTicket + ' - ' + SubjectTicket  + '\n\n' + ContentTicket
        })
        if (send.data) {
            
        } else {
          //  alert("Dados não encotrados")
        }
        
    }

    

    const getChatTokens = async () => {
        try { let res = await Api.getChatTokens('JOY')           
                    const tokenGet = res.map((obj) => {
                        return obj.token
                    })

                    setTokens(tokenGet)
        } catch(err){
          //  alert("Dados não encotrados")
        }
        

    }

    useEffect(()=>{
        getChatTokens()
    },[]);


    // console.log(subjectUpper)
    // console.log(subject)
    console.log(fullSubject)


    return (
        <C.Container>
            <C.SpecialButton onClick={() => ModalClick()} style={{marginBottom: '30px', padding: '12px' }}>
            <C.IconArea><AddCircleIcon fontSize="large"/></C.IconArea>
            <C.Title style={{marginLeft: '10px', fontSize: '16px' }}>Criar Chamado</C.Title>
            </C.SpecialButton>
            <C.Button onClick={() => MenuItemClick('/Dashboard')}>
            <C.IconArea><HomeIcon/></C.IconArea>
            <C.Title>Dashboard</C.Title>
            </C.Button>
            <C.Button onClick={() => MenuItemClick('/Tickets')}>
            <C.IconArea><TaskIcon/></C.IconArea>
            <C.Title>Chamados</C.Title>
            </C.Button>
            <C.Button onClick={() => MenuItemClick('/Services')}>
            <C.IconArea><SellIcon/></C.IconArea>
            <C.Title>Serviços</C.Title>
            </C.Button>
            <C.Button onClick={() => MenuItemClick('/Contacts')}>
            <C.IconArea><ContactEmergencyIcon/></C.IconArea>
            <C.Title>Contatos</C.Title>
            </C.Button>

            
            
            {modal &&
            <C.ModalContainer>
                 <C.Options onClick={CloseModalClick}><CloseIcon/></C.Options>
            {step === 1 &&
                 <C.Content>
                   <C.BodyArea>
                    <C.Info>Escolha uma das opções abaixo:</C.Info>
                    <C.Button onClick={() => Step1Click(2,'','CLOUD')}>
                    <C.IconArea><CloudIcon/></C.IconArea>
                    <C.Title>Cloud</C.Title>
                    </C.Button>
                    <C.Button onClick={() => Step1Click(2,'','INFRA')}>
                    <C.IconArea><LanIcon/></C.IconArea>
                    <C.Title>Infraestrutura</C.Title>
                    </C.Button>
                    <C.Button onClick={() => Step1Click(2,'','SERVIDOR')}>
                    <C.IconArea><StorageIcon/></C.IconArea>
                    <C.Title>Servidor</C.Title>
                    </C.Button>
                    <C.Button onClick={() => Step1Click(2,'','SOLUÇÕES')}>
                    <C.IconArea><CodeIcon/></C.IconArea>
                    <C.Title>Soluções Joy</C.Title>
                    </C.Button>
                    <C.Button onClick={() => Step1Click(2,'','DÚVIDA')}>
                    <C.IconArea><HelpIcon/></C.IconArea>
                    <C.Title>Duvida</C.Title>
                    </C.Button>
                    <C.Button onClick={() => StepClick(11, 1)}>
                    <C.IconArea><PhoneIcon/></C.IconArea>
                    <C.Title>Solicitar Ligação</C.Title>
                    </C.Button>   
                    <C.Button  onClick={() => StepClick(1,0)}>
                    <C.IconArea><LabelIcon/></C.IconArea>
                    <C.Title>Tenho um ID</C.Title>
                    </C.Button>
                    {alert === 0 && 
                      <C.IconArea style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '180px'}}>
                          <C.Input  
                            style={{fontSize:'16px', textAlign: 'center', marginRight: '10px',  justifyContent: 'center'}}
                            placeholder="XXXX-XX-0000"
                            type="text"
                            value={tag}
                            maxLength="12"
                            onChange={e=>setTag(e.target.value.toUpperCase())}/>
                            {tag  && tag.length > 10 &&
                            <ArrowForwardIosIcon onClick={() => Step1Click(2,'', tag)}/>
                            }
                        </C.IconArea>
                    }
                </C.BodyArea>
                </C.Content>
                }
                {step === 11 &&
                <C.Content>
                    <C.BodyArea>
                    {alert === 1 &&
                    <C.Info style={{borderLeft: '5px solid #333', padding: '10px', marginTop: '70px', width: '500px'}}>Você está solicitando um contato por telefone, a solicitação só é valida para horário comercial e o prazo de retorno é de até 1 Hora. <br></br><br></br>Deseja continuar ?</C.Info>
                    }
                     {alert === 2 &&
                    <C.Info style={{marginTop: '70px', width: '500px'}}> {alertText}</C.Info>
                     }{alert === 2 &&
                    <C.Logo src={alertIcon} />
                     }
                    {loading &&
                    <C.Logo src={"/loading.svg"} />
                    }
                    </C.BodyArea>
                    {alert === 1 &&
                    <C.FooterArea>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(1)}>
                        <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                        <C.Title>Não</C.Title>
                        </C.Button>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => CallClick()}>
                        <C.Title>Sim</C.Title>
                        <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                    </C.FooterArea>
                    }
                </C.Content>
                }

                {step === 2 &&
                <C.Content>
                    <C.BodyArea>
                    <C.Info>Qual o assunto da demanda ?</C.Info>
                    <C.Input
                            autoFocus  
                            type="text"
                            value={subject}
                            onChange={e=>setSubject(e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1))}/>
                    </C.BodyArea>
                    <C.FooterArea>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(1)}>
                            <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                            <C.Title>Anterior</C.Title>
                        </C.Button>
                        {subject &&
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(3)}>
                            <C.Title>Próximo</C.Title>
                            <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                        }
                    </C.FooterArea>

                </C.Content>
                }
                {step === 3 &&
                <C.Content>
                    <C.BodyArea>
                    <C.Info>Descreva os detalhes de sua solicitação:</C.Info>
                    <C.TicketBody 
                    type="text"
                    value={content}
                    onChange={e=>setContent(e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1))}
                    autoFocus
                    >
                    </C.TicketBody>
                    </C.BodyArea>
                    <C.FooterArea>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(2)}>
                            <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                            <C.Title>Anterior</C.Title>
                        </C.Button>
                        {content &&
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(4)}>
                            <C.Title>Próximo</C.Title>
                            <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                        }
                   </C.FooterArea>

                </C.Content>
                }
                {step === 4 &&
                <C.Content>
                    <C.BodyArea>
                    <C.Info>Deseja anexar uma foto ou documento a solicitação ?</C.Info>
                    <C.Logo src={"/file.svg"} />
                    <C.Input 
                            placeholder='Escolha o arquivo'
                            onChange={e=>setSelectedFile(e.target.files[0])}
                            type="file"/>
                    </C.BodyArea>
                    <C.FooterArea>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(3)}>
                        <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                        <C.Title>Anterior</C.Title>
                        </C.Button>
                        <C.Button style={{width: "160px", justifyContent: 'center'}} onClick={() => StepClick(5)}>
                        <C.Title>{selectedFile.length === 0 ? 'Não é necessário' : 'Próximo' }</C.Title>
                        <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                   </C.FooterArea>

                </C.Content>
                }
                    {step === 5 &&
                <C.Content>
                    <C.BodyArea>
                    <C.Info style={{}}>Esse é um chamado de emergêcia ?</C.Info>
                    <C.Info style={{borderLeft: '5px solid #333', marginTop: '40px', width: '500px', padding: '10px'}}>Para o chamado ser considerado de emergência, deve ser um servidor, ou no caso em que o incidente afeta mais que 2 usuários</C.Info>
                         <C.Button style={{width: "320px", justifyContent: 'center', marginTop: '40px', padding: '10px'}} onClick={() => Step5Click(6, 'EM1-R')}>
                        <C.Title>É uma solicitação de emergência!</C.Title>
                        <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                        <C.Button style={{width: "320px", justifyContent: 'center', marginTop: '30px', padding: '10px'}} onClick={() => Step5Click(6, 'SUP-R')}>
                        <C.Title>Não, é uma solicitação padrão!</C.Title>
                        <C.IconArea><ArrowForwardIosIcon/></C.IconArea>
                        </C.Button>
                    </C.BodyArea>
                    <C.FooterArea>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(4)}>
                        <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                        <C.Title>Voltar</C.Title>
                        </C.Button>

                   </C.FooterArea>

                </C.Content>
                }

                {step === 6 &&
                <C.Content>
                     {alert === 2 &&
                    <C.Info style={{marginTop: '70px', width: '500px'}}> {alertText}</C.Info>
                     }{alert === 2 &&
                    <C.Logo src={alertIcon} />
                     }
                       {loading &&
                    <C.Logo src={"/loading.svg"} />
                    }
                    {alert === 0 &&
                    <C.FooterArea style={{marginTop: '0px'}}>
                        <C.Button style={{width: "90px", justifyContent: 'center'}} onClick={() => StepClick(5)}>
                        <C.IconArea><ArrowBackIosIcon/></C.IconArea>
                        <C.Title>Anterior</C.Title>
                        </C.Button>
                        <C.SpecialButton style={{width: "180px", justifyContent: 'center'}} onClick={() => TicketClick()}>
                        <C.Title >Abrir o chamado</C.Title>
                        <OpenInNewIcon style={{ marginLeft: '10px'}}/>
                        </C.SpecialButton>
                    </C.FooterArea>
                    }
                    {alert === 0 &&
                    <C.BodyArea style={{marginTop: '20px', border: '1px dotted #222', width: '560px',  alignItems: 'flex-start', borderRadius: '10px'}} >
                    <C.SumaryHeader><TaskIcon style={{ color: '#000', fontSize: '40px'}}/><strong>{fullSubject}</strong></C.SumaryHeader>
                    <C.SumaryBody dangerouslySetInnerHTML={{ __html: content.replace(/(\r\n|\r|\n)/g, '<br>') }}></C.SumaryBody>
                    <C.SumaryFooter>
                        <C.Info style={{ height: '20px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start', alignItems: 'center', padding: '0px'}}>
                        <C.Title><SellIcon style={{ color: '#000', fontSize: '30px'}}/></C.Title>
                        <C.Info style={{ marginLeft: '4px', fontSize: '14px'}}>{service}</C.Info>
                        <C.Title style={{ marginLeft: '20px'}}><PendingActionsIcon style={{ color: '#000', fontSize: '30px'}}/></C.Title>
                        <C.Info style={{ marginLeft: '4px', fontSize: '14px'}}>{isEmergency}</C.Info>
                        <C.Title style={{ marginLeft: '20px'}}><AttachFileIcon style={{ color: '#000', fontSize: '28px'}}/></C.Title>
                        <C.Info style={{ marginLeft: '4px', fontSize: '14px'}}>{selectedFile.name ? selectedFile.name : 'Sem anexo'}</C.Info>
                        </C.Info>
                    </C.SumaryFooter>
                    </C.BodyArea>
                    }
                </C.Content>
                }
            </C.ModalContainer>
            }
        </C.Container>
    );
}
