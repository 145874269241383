import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import * as C from './styled'

import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BusinessIcon from '@mui/icons-material/Business';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import ArchiveIcon from '@mui/icons-material/Archive';

export const Archive = () => {
    const navigate = useNavigate();

    const { state: user } = useContext(UserContext)

    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([])
    const [switchButton, setSwitchButton] = useState(false)
    const [dateFilter, setDateFilter] = useState('2022')
    const [empty, setEmpty] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState('')


    const GoClick = () => {
      navigate('/Tickets');
  }

    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase();
      let result = [];

      result = list.filter((data) => {
      if (/\d/.test(value)) {
      return data.TicketNumber.toString().search(value) != -1; }
      else {
        return data.Title.toLowerCase().search(value) != -1; }
      });

      setFilteredList(result);
      }

      const Download = (base64, fileName, fileContent) => {
          let url = `data:${fileContent};base64,${base64}`;
					let a = document.createElement('a');
					a.href = url;
					a.download = fileName;
					a.click();
    }
      const TicketClick = async (ticketID) => {
        setLoadingModal(true)
        let res = await Api.getCustomerTicketZoom(ticketID);
        setSelectedTicket(res.Ticket[0])
        setLoadingModal(false)
        setModal(true)

    }


    const RefreshClick = (Year) => {
      document.getElementById('search').value = '';
      setFilteredList([])
      setDateFilter(Year)
      }


    const CloseModalClick = () => {
      setModal(false)
    }


    const getCustomerTickets = async () => {
      setEmpty(false)
      setFilteredList([])
      document.getElementById('search').value = '';
      setLoading(true)
      let res2 = await Api.getCustomerArchTickets(dateFilter, switchButton ? user.user : '');
      if (res2.Ticket) {
          setEmpty(false)
          setList(res2.Ticket)
          setFilteredList(res2.Ticket)
      } else {
          setEmpty(true)
      }
      setLoading(false);
    }

    useEffect(()=>{

      getCustomerTickets();

      }, [dateFilter, switchButton]);


    return (
        <C.Container>
            <C.Header style={{color: '#000', borderLeft: '5px solid red'}}>
                <C.TitleArea>
                <ArchiveIcon  fontSize="large" style={{marginRight: "7px"}}/>
                <h2>| Chamados Arquivados</h2>
                </C.TitleArea>
                <C.SearchArea>
                <C.SwitchArea>
                  <BusinessIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #2E8B57' }}/>
                  <C.IconButton onClick={() => setSwitchButton(switchButton === false ? true : false)}>
                   <PublishedWithChangesIcon fontSize="small"/>
                  </C.IconButton>
                  <AccountCircleIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #2E8B57' : 'none' }}/>
                </C.SwitchArea>
                  <SearchIcon />
                  <C.SearchInput id='search' onChange={(event) => handleSearch(event)} >
                  </C.SearchInput>
                  <C.Button onClick={GoClick} style={{backgroundColor: '#333', marginLeft: '15px'}}>Voltar Ativos <OpenInNewIcon style={{marginLeft: '7px'}} fontSize="small" /></C.Button>
                  <C.Button style={{backgroundColor: '#ffb400', width: '25px', color: '#333'}}><HelpIcon  fontSize="medium" /></C.Button>
                </C.SearchArea>
                
            </C.Header>
            <C.StateArea  style={{backgroundColor: '#fff', borderLeft: '2px solid #ccc', borderRadius: '0px'}}>
            <ArchiveIcon  fontSize="large" style={{marginRight: "7px"}}/>
            <C.CardHeaderTitle>Número de Chamados: <strong> {filteredList.length} </strong></C.CardHeaderTitle>
              <C.Button onClick={() => RefreshClick('2024')} style={{backgroundColor: dateFilter === '2024' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2024</C.Button>      
              <C.Button onClick={() => RefreshClick('2023')} style={{backgroundColor: dateFilter === '2023' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2023</C.Button>      
              <C.Button onClick={() => RefreshClick('2022')} style={{backgroundColor: dateFilter === '2022' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2022</C.Button>
              <C.Button onClick={() => RefreshClick('2021')} style={{backgroundColor: dateFilter === '2021' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2021</C.Button>
              <C.Button onClick={() => RefreshClick('2020')} style={{backgroundColor: dateFilter === '2020' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2020</C.Button>
              <C.Button onClick={() => RefreshClick('2019')} style={{backgroundColor: dateFilter === '2019' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2019</C.Button>         
            </C.StateArea>
            <C.Body>
                {empty &&
                <C.CardHeaderTitle style={{fontSize: '15px', marginTop: '150px', borderBottom: '1px solid #333', paddingBottom: '7px'   }}>
                      Não existem chamados para esse filtro dentro do ano de <strong>{dateFilter}</strong> 
                </C.CardHeaderTitle>
                }        
                <C.Card >
                {loading &&  
                <C.Logo src={"/loading.svg"} />
                }  
                {filteredList.map((item, key)=>(
                  <C.CardHeader  key={key} >
                    <C.CardHeaderInfo>
                    <C.CardHeaderNumber>Chamado: {item.TicketNumber}</C.CardHeaderNumber>
                    <C.CardHeaderTitle style={{fontSize: '13px'}}>
                      { item.Title.length > 90 ? item.Title.substring(0,90)+' ...' : item.Title }
                      </C.CardHeaderTitle>
                    </C.CardHeaderInfo>
                    <C.CardBodyText style={{fontSize: '13px', borderBottom: '5px solid #E95839', backgroundColor: '#F5F5F5', padding: '7px 12px' }}>
                    <strong>{                              item.State==='closed successful' ? 'Fechado com exito' :
                                                           item.State==='closed unsuccessful' ? 'Fechado sem exito' :
                                                           item.State}</strong>
                    </C.CardBodyText>
                    <C.CardBodyStatusText >
                      <strong>Data: </strong>{item.Created}
                      </C.CardBodyStatusText>
                       <C.CardHeaderLogo >
                    <OpenInNewIcon onClick={() => TicketClick(item.TicketID)} style={{color: '#333', cursor: 'pointer' }} fontSize="large"/>
                    </C.CardHeaderLogo>
                  </C.CardHeader>
                                  ))}

                </C.Card>
                {filteredList.length > 20 &&    
        <C.StateArea  style={{backgroundColor: '#fff', borderLeft: '2px solid #ccc', borderRadius: '0px', marginBottom: '40px'}}>
            <ArchiveIcon  fontSize="large" style={{marginRight: "7px"}}/>
            <C.CardHeaderTitle>Número de Chamados: <strong> {filteredList.length} </strong></C.CardHeaderTitle>
              <C.Button onClick={() => RefreshClick('2023')} style={{backgroundColor: dateFilter === '2023' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2023</C.Button>    
              <C.Button onClick={() => RefreshClick('2022')} style={{backgroundColor: dateFilter === '2022' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2022</C.Button>
              <C.Button onClick={() => RefreshClick('2021')} style={{backgroundColor: dateFilter === '2021' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2021</C.Button>
              <C.Button onClick={() => RefreshClick('2020')} style={{backgroundColor: dateFilter === '2020' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2020</C.Button>
              <C.Button onClick={() => RefreshClick('2019')} style={{backgroundColor: dateFilter === '2019' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2019</C.Button>         
            </C.StateArea>
                }
            </C.Body>
            {loadingModal &&  
            <C.ModalContainer>
              <C.Logo src={"/loading.svg"} />
            </C.ModalContainer>
            }
            {modal !== false &&
            <C.ModalContainer>
              <C.Options style={{}}>
                  <C.CardHeaderLogo style={{ }}>
                     <HighlightOffIcon onClick={CloseModalClick} style={{color: '#333', cursor: 'pointer' }} fontSize="large"/>
                   </C.CardHeaderLogo>
              </C.Options>
              <C.Content>
              <C.Header id='view' style={{justifyContent: 'space-around', marginTop: '35px' }}>


                    <C.CardHeaderTitle style={{ fontSize: '20px', marginLeft: '10px'}}>
                   Chamado #{selectedTicket.TicketNumber} - {selectedTicket.Title}
                 </C.CardHeaderTitle>

              </C.Header>
              <C.ModalBody>
                <C.ModalDetails>

                      <C.CardBodyText style={{width: '180px', fontSize: '15px', borderBottom: selectedTicket.StateType === 'open' ? '5px solid #2E8B57' :
                                                                            selectedTicket.StateType === 'new' ? '5px solid #1E90FF' :
                                                                            selectedTicket.StateType === 'pending reminder' ? '5px solid #FCC703' : '5px solid #E95839',
                                                              backgroundColor: selectedTicket.State === 'Em Atendimento' ? '#F1FFF3' :
                                                              selectedTicket.State === 'Aguardando Cliente' ? '#FCF4DB' : '#f5f5f5',
                                                                               padding: '7px 12px' }}>
                    <strong>{selectedTicket.State==='new' ? 'Novo' : selectedTicket.State==='open' ? 'Aberto' :
                                                           selectedTicket.State==='closed successful' ? 'Fechado com exito' :
                                                           selectedTicket.State==='closed unsuccessful' ? 'Fechado sem exito' :
                                                          selectedTicket.State}</strong>

                    </C.CardBodyText>

                      <C.CardBodyStatusText >
                      <strong>Criado: </strong>{selectedTicket.Created}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Fila</strong>: {selectedTicket.Queue}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText style={{backgroundColor: selectedTicket.SLA === 'EM1-R' && 'EM2-R' ? '#E95839' : '#1E90FF', padding: '3px 7px', color: '#fff'  }}>
                      <strong>SLA: {selectedTicket.SLA}</strong>
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Tipo: </strong> {selectedTicket.Type}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Serviço: </strong> {selectedTicket.Service}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Criado por: </strong> {selectedTicket.CustomerUserID}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Responsável: </strong> {selectedTicket.Responsible}
                      </C.CardBodyStatusText>
                      <C.CardBodyText style={{borderLeft: '5px solid #2E8B57', width: '180px' }}>
                      Prazo para inicio: {selectedTicket.EscalationDestinationDate}
                      </C.CardBodyText>
                      <C.CardBodyText style={{borderLeft: '5px solid #E95839', width: '180px' }}>
                      Prazo para solução: {selectedTicket.SolutionTimeDestinationDate}
                      </C.CardBodyText>
                </C.ModalDetails>
                <C.ModalContentBody>
      
                {selectedTicket.Article.map((article, key)=>(
                    <C.Article style={{borderLeft: article.SenderType !== 'customer' ? '3px solid #FFB400' : ''}} key={key}>
                    <C.ArticleHeader style={{justifyContent: 'space-between'}}>
                    <C.CardHeaderLogo style={{padding:'10px', borderRadius: '10px', backgroundColor: '#f1f1f1', marginLeft: '20px', marginRight: '-30px'}}>
                      {article.ArticleNumber}
                    </C.CardHeaderLogo>
                      <C.CardHeaderInfo >
                      <C.CardHeaderNumber>{article.From}</C.CardHeaderNumber>
                      <C.CardHeaderTitle >{ article.Subject}</C.CardHeaderTitle>
                      </C.CardHeaderInfo>
                      <C.CardHeaderNumber style={{marginRight: '10px', fontWeight: 'normal', fontSize: '12px'}} >
                      { article.CreateTime}
                      </C.CardHeaderNumber>
                    </C.ArticleHeader>
                    <C.ArticleBody>
                    <div dangerouslySetInnerHTML={{ __html: article.Body.replace(/\n/g, "<br />") }}></div>
                    </C.ArticleBody>
                    {article.Attachment &&
                    <C.ArticleFooter style={{flexDirection: 'column'}}>
                    {article.Attachment.map((attachment, key)=>(
                      <C.ArticleFilesArea >
                       <C.Button style={{backgroundColor: attachment.ContentType === 'application/pdf' ? '#E95839' :
                                                          attachment.ContentType === 'image/png' ? '#1E90FF' : '#FCC703',
                       width: '0px' ,minWidth: '70px' , color: '#000', fontWeight: 'normal', margin: '0px' }}>
                                                          {attachment.ContentType === 'application/pdf' ? 'PDF' :
                                                          attachment.ContentType === 'image/png' ? 'IMAGEM' : 'OUTROS'}
                        </C.Button>  
                      <C.Button style={{backgroundColor:'#34495e', minWidth: '220px' , color: '#ddd', fontWeight: 'normal' , marginLeft: '-10px' }}>
                        {attachment.Filename.length > 25 ? attachment.Filename.substring(0,25)+' ...' : attachment.Filename}</C.Button>
                      <C.Button style={{backgroundColor:'#2E8B57', width: '30px', marginLeft: '-20px'}} onClick={() => 
                        Download(attachment.Content, attachment.Filename, attachment.ContentType)}><FileDownloadIcon/></C.Button>
                      
                      </C.ArticleFilesArea>
                    ))}
                    </C.ArticleFooter>
                    }
                    </C.Article>
                  )).reverse()}

                 </C.ModalContentBody>
                 </C.ModalBody>
              </C.Content>

            </C.ModalContainer>

            }

        </C.Container>

    );
}
