export const initialState = {
    user: '',
    id: '',
    name: '',
    company: '',
    email: '',
    chat: [],
};

export const UserReducer = (state, action) => {
    
    switch (action.type) {
        case 'setUser':
                return { ...state, 
                user: action.payload.user, 
                id: action.payload.id, 
                name: action.payload.name,
                company: action.payload.company,
                email: action.payload.email,
                legal: action.payload.legal,
                manager: action.payload.manager,
                tec: action.payload.tec,
                adm: action.payload.adm    
            }

        case 'setChat':
                return { ...state, 
                    chat: action.payload.chat 
                }


        default:
        return state;
    }
}