import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
margin-top: 100px;
flex: 1;
z-index: 10;
`;

export const Button = styled.div`
display: flex;
align-items: center;
padding: 7px;
width: 160px;
height: 20px;
margin-top: 15px;
color: #333;
border-bottom: 2px dotted #333;
cursor: pointer;
&:hover {
  border-bottom: 2px solid #333;

}
`;

export const SpecialButton = styled.div`
display: flex;
align-items: center;
padding: 7px;
width: 160px;
height: 20px;
margin-top: 15px;
border-bottom: 2px solid #2E8B57;
color: #000;
cursor: pointer;
&:hover {
  border-bottom: 2px solid #333;
}
`;

export const IconArea = styled.div`
width: 30px
`;

export const Title = styled.div`
font-weight: bold;
font-size: 16px
`;

//#########################################MODAL

export const ModalContainer = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`;


export const Content = styled.div`
background-color: #ffb400;
height: 465px;
width: 700px;
border-radius: 10px;
display: flex;
justify-content: start;
align-items: center;
padding: 30px;
margin-bottom: 20px;
flex-direction: column;

`;

export const BodyArea = styled.div`
    height: 465px;
    flex-direction: column;
    align-items: center;
    display: flex;
    font-size: 14px;
    padding: 15px ;

`;

export const FooterArea = styled.div`
    margin-top: 10px;
    width: 90%;
    flex-direction: row; 
    justify-content: space-between;
    display: flex;
`;

export const Input = styled.input`
    font-size: 14px;
    margin-top: 10px;
    background-color: rgba(52, 52, 52, 0.1);  
    width: 500px;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    font-family: inherit;
    outline: none;
    color: #333;
`;

export const TicketBody = styled.textarea`
font-size: 14px;
margin-top: 10px;
background-color: rgba(52, 52, 52, 0.1);  
width: 500px;
border-radius: 15px;
padding: 25px;
margin-bottom: 10px;
border: none;
font-family: inherit;
outline: none;
color: #333;
height: 250px;
resize: none;
font-family: inherit;
   font-size: inherit;
   &::-webkit-scrollbar {
    width: 2px;
    heigth: 2px;
}
&::-webkit-scrollbar-thumb {
    background-color: #333;   
`;

export const Info = styled.div`
font-size: 16px;
color: #333;
font-weight: normal;
padding: 10px 0px;
`;

export const Options = styled.div`
background-color: #ffb400;
margin-left: 700px;
height: 30px;
padding: 10px;
margin-bottom: -60px;
z-index: 3;
cursor: pointer;
`;

export const Logo = styled.img`
width: auto;
height: 170px;
margin-top: 20px;

`;


export const SumaryHeader = styled.div`
min-height: 40px;
width: 100%;
color: #333;
justify-content: start;
align-items: center;
display: flex;
border-bottom: 1px dotted #555;
margin-bottom: 20px;
padding-bottom: 10px;

`
export const SumaryBody = styled.div`
min-height: 200px;
max-height: 200px;
width: 100%;
justify-content: center;
align-items: center;
color: #333;
overflow: auto;
margin-bottom: 20px;
padding-bottom: 20px;
border-bottom: 1px dotted #555;
&::-webkit-scrollbar {
    width: 1px;
    heigth: 1px;
}
&::-webkit-scrollbar-thumb {
    background-color: #333;   
`
export const SumaryFooter = styled.div`
min-height: 40px;
width: 100%;
display: flex;
color: #333;
justify-content: space-around;
align-items: center;
display: flex;
flex-direction: column;

`



