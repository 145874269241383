import React, { useState, useEffect, useContext } from 'react';
import { useNavigate,useSearchParams } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import * as C from './styled'
import * as G from '../../styles/global'
import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import TaskIcon from '@mui/icons-material/Task';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import ReplyIcon from '@mui/icons-material/Reply';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Alert } from '@mui/material';

export const Tickets = () => {
    const navigate = useNavigate();

    const { state: user } = useContext(UserContext)
    const [searchParams] = useSearchParams()

    const [tokens, setTokens] = useState([])

    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([])
    const [switchButton, setSwitchButton] = useState(false)
    const [state, setState] = useState('')
    const [empty, setEmpty] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [showReply, setShowReply] = useState(false)
    const [showClose, setShowClose] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState('')
    const [content, setContent] = useState('')
    const [selectedFile, setSelectedFile] = useState([])
    const [convertedFile, setConvertedFile] = useState([])

    const Validate = () => {

      let contentUpper = ((content.length - content.replace(/[A-Z]/g, '').length) / (content.length - 1)) * 100;

          
      if (contentUpper > 50) {
        replyClick(content.charAt(0).toUpperCase() + content.toLowerCase().substring(1));
      } else {
        replyClick(content.charAt(0).toUpperCase() + content.substring(1));
      }

    }


    const Convert = async () => {
        
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
  
      reader.onload = () => {
        let base64result = reader.result.split(',')[1]
          setConvertedFile(base64result)
      };

    }


    const GoClick = () => {
      navigate('/Archive');
  }

  const Download = (base64, fileName, fileContent) => {
          let url = `data:${fileContent};base64,${base64}`;
					let a = document.createElement('a');
					a.href = url;
					a.download = fileName;
					a.click();
}



    const handleSearch = (event) => {
      let value = event.target.value.toLowerCase();
      let result = [];

      result = list.filter((data) => {
      if (/\d/.test(value)) {
      return data.TicketNumber.toString().search(value) != -1; }
      else {
        return data.Title.toLowerCase().search(value) != -1; }
      });

      setFilteredList(result);
      }


    const ShowReplyClick = () => {
        setShowClose(false)
        setShowReply(true)
        document.getElementById('view')?.scrollIntoView({ behavior: 'smooth' })
    }

    const ShowCloseClick = () => {
      setShowReply(false)
      if (showClose === false) {
        setShowClose(true)
        document.getElementById('view')?.scrollIntoView({ behavior: 'smooth' })

      } else {
        setShowClose(false)
      }      

  }

    const RefreshClick = (StateTicket) => {
      document.getElementById('search').value = '';
      setFilteredList([])
      setState(StateTicket)
      }

      const TicketClick = async (ticketID) => {
        setLoadingModal(true)
        let res = await Api.getCustomerTicketZoom(ticketID);
        setSelectedTicket(res.Ticket[0])
        setLoadingModal(false)
        setModal(true)

    }

    const CloseModalClick = () => {
      setShowReply(false)
      setShowClose(false)
      setModal(false)
      navigate('/Tickets')
      //getCustomerTickets();

    }


    const getCustomerTickets = async () => {
      setEmpty(false)
      setFilteredList([])
      setLoading(true)
      let res2 = await Api.getCustomerTickets(state, switchButton ? user.user : '');
      console.log(res2)
      if (res2.Ticket) {
          setEmpty(false)
          setList(res2.Ticket)
          setFilteredList(res2.Ticket)
      } else {
          setEmpty(true)
      }
      setLoading(false);
    }


    const replyClick = async (endContent) => {
          setShowReply(false)
          setLoading(true)
          if (selectedFile.length === 0) 
          {
          let send = await Api.sendArticle( selectedTicket.TicketID, user.name+" <"+user.email+">", endContent.replace(/(\r\n|\r|\n)/g, '<br>'), selectedTicket.Title, 'open' )
          if (send.ArticleID) {
            setContent('')
            sendNotification(content.replace(/(\r\n|\r|\n)/g, '<br>'))
            setSelectedFile([])
            setLoading(false)
            setModal(false)
            getCustomerTickets()                  
          } else {


          }}
          else {
           let send = await Api.sendArticleImage(selectedTicket.TicketID, user.name+" <"+user.email+">", endContent.replace(/(\r\n|\r|\n)/g, '<br>'), selectedTicket.Title, convertedFile, selectedFile.name, selectedFile.type, 'open')
           if (send.ArticleID) {
              setContent('')
              sendNotification(content.replace(/(\r\n|\r|\n)/g, '<br>'))
              setSelectedFile([])
              setLoading(false)
              setModal(false)
              getCustomerTickets()         
            } else {

            }
        }
        
        }



        const sendNotification = async ( body ) => {
       
          let send = await Api.sendTokens({
            "to":  tokens,
            "sound": "default",
            "title": user.name + ' (' + user.id + ') abriu um novo chamado',
            "body":  selectedTicket.TicketNumber + ' - ' + selectedTicket.Title  + '\n\n' + body
        })
        if (send.data) {
            
        } else {
          //  alert("Dados não encotrados")
        }
        
    }

    const closeClick = async () => {
      setShowClose(false)
      setLoading(true)
      let send = await Api.sendArticle( selectedTicket.TicketID, user.name+" <"+user.email+">", 'O chamado foi encerrado por ' + user.name, selectedTicket.Title, 'Fechado pelo Cliente' )
      if (send.ArticleID) {
        setContent('')
        sendNotification('O chamado foi encerrado pelo Cliente')
        setSelectedFile([])
        setLoading(false)
        setModal(false)
        getCustomerTickets()         
      } else {


      }
     
    }
    

    const getChatTokens = async () => {
      try { let res = await Api.getChatTokens('JOY')           
                  const tokenGet = res.map((obj) => {
                      return obj.token
                  })

                  setTokens(tokenGet)
      } catch(err){
        //  alert("Dados não encotrados")
      }
      

  }



    useEffect(()=>{
      getChatTokens()
      if (searchParams.get('TicketID')){
        TicketClick(searchParams.get('TicketID'))
      } else {
        
      }
      getCustomerTickets();

      }, [state, switchButton]);

      useEffect(()=>{
        Convert()
        }, [selectedFile]);
  
  
    

    return (
        <C.Container>

            <C.Header >
                <C.TitleArea >
                <TaskIcon  fontSize="large" style={{marginRight: "10px"}}/>
                <h2>| Chamados</h2>
                </C.TitleArea>
                <C.SearchArea>
                <C.SwitchArea>
                  <BusinessIcon data-tooltip-id="my-tooltip"
                                data-tooltip-delay-show='2000' data-tooltip-variant='info'
                                data-tooltip-content="Exibe todos os chamados criados por todos os contatos da empresa"
                                style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #2E8B57' }}/>
                  <C.IconButton onClick={() => setSwitchButton(switchButton === false ? true : false)}>
                   <PublishedWithChangesIcon fontSize="small"/>
                  </C.IconButton>
                  <AccountCircleIcon  data-tooltip-id="my-tooltip"
                                      data-tooltip-delay-show='2000' data-tooltip-variant='info'
                                      data-tooltip-content="Exibe apenas os chamados criados por vocë"
                                      style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #2E8B57' : 'none' }}/>
                </C.SwitchArea>
                 <C.StateArea>
                  {state !== '' &&
                  <CloseIcon onClick={() => RefreshClick('')}/>
                  }
                  <TaskIcon data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados NOVOS"
                            fontSize={state === 'new' ? 'large' :'medium'} onClick={() => RefreshClick('new')} style={{color: '#1E90FF', cursor: 'pointer'}}/>
                  <TaskIcon data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados ABERTOS"
                            fontSize={state === 'open' ? 'large' :'medium'} onClick={() => RefreshClick('open')} style={{color: '#2E8B57', cursor: 'pointer'}}/>
                  <TaskIcon data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados PENDENTES"
                            fontSize={state === 'pending reminder' ? 'large' :'medium'} onClick={() => RefreshClick('pending reminder')}  style={{color: '#FCC703', cursor: 'pointer'}}/>
                  <TaskIcon data-tooltip-id="my-tooltip"
                            data-tooltip-delay-show='2000' data-tooltip-variant='info'
                            data-tooltip-content="Exibe apenas os chamados FECHADOS há menos de 15 dias"
                            fontSize={state === 'closed' ? 'large' :'medium'} onClick={() => RefreshClick('closed')}  style={{color: '#E95839', cursor: 'pointer'}}/>
                  </C.StateArea>
                  <SearchIcon />
                  <C.SearchInput id='search' disabled={state !== '' ? true : false} onChange={(event) => handleSearch(event)} >
                  </C.SearchInput>
                  <C.Button onClick={GoClick} style={{backgroundColor: '#333', marginLeft: '15px'}}>Arquivados <OpenInNewIcon style={{marginLeft: '7px'}} fontSize="small" /></C.Button>
                </C.SearchArea>
            </C.Header>

            <C.Body>
                {loading &&  
                <C.Logo src={"/loading.svg"} />
                }  
                {empty &&
                  <C.CardHeaderTitle style={{fontSize: '16px', marginTop: '150px', borderBottom: '1px solid #333', paddingBottom: '7px' }}>
                    Não existem itens para o status selecionado
                  </C.CardHeaderTitle>
                }

                 {filteredList.map((item, key)=>(
                <C.Card key={key} >
                  <C.CardHeader >
                    <C.CardHeaderLogo>
                    <TaskIcon style={{color: item.StateType === 'open' ? '#2E8B57' :
                                              item.StateType === 'new' ? '#1E90FF' :
                                              item.StateType === 'pending reminder' ? '#FCC703' : '#E95839'}}  fontSize="large"/>
                    </C.CardHeaderLogo>
                    <C.CardHeaderInfo>
                    <C.CardHeaderNumber>Chamado: {item.TicketNumber}</C.CardHeaderNumber>
                    <C.CardHeaderTitle style={{fontSize: '13px'}}>
                      { item.Title.length > 50 ? item.Title.substring(0,50)+' ...' : item.Title }
                      </C.CardHeaderTitle>
                    </C.CardHeaderInfo>
                    <C.CardHeaderLogo >
                    <OpenInNewIcon onClick={() => TicketClick(item.TicketID)} style={{color: '#333', cursor: 'pointer' }} fontSize="large"/>
                    </C.CardHeaderLogo>
                  </C.CardHeader>
                  <C.CardBody>
                    <C.CardBodyA>
                    <C.CardBodyText style={{fontSize: '13px', borderBottom: item.StateType === 'open' ? '5px solid #2E8B57' :
                                                                            item.StateType === 'new' ? '5px solid #1E90FF' :
                                                                            item.StateType === 'pending reminder' ? '5px solid #FCC703' : '5px solid #E95839',
                                                              backgroundColor: item.State === 'Em Atendimento' ? '#F1FFF3' :
                                                                               item.State === 'Aguardando Cliente' ? '#FCF4DB' : '#F5F5F5',
                                                                               padding: '7px 12px' }}>
                    <strong>{item.State==='new' ? 'Novo' : item.State==='open' ? 'Aberto' :
                                                           item.State==='closed successful' ? 'Fechado com exito' :
                                                           item.State==='closed unsuccessful' ? 'Fechado sem exito' :
                                                           item.State}</strong>
                    </C.CardBodyText>
                    <C.CardBodyText style={{borderLeft: '5px solid #2E8B57' }}>
                    Prazo para inicio: {item.EscalationDestinationDate} 
                    </C.CardBodyText>
                    <C.CardBodyText style={{borderLeft: '5px solid #E95839' }}>
                    Prazo para solução: {item.SolutionTimeDestinationDate}
                    </C.CardBodyText>
                    </C.CardBodyA>
                    <C.CardBodyB>
                    <C.CardBodyStatusText >
                      <strong>Criado: </strong>{item.Created}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Fila</strong>: {item.Queue}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText style={{backgroundColor: item.SLA === 'EM1-R' && 'EM2-R' ? '#E95839' : '#1E90FF', padding: '3px 7px', color: '#fff'  }}>
                      <strong>SLA: {item.SLA}</strong>
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Tipo: </strong> {item.Type}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Serviço: </strong> {item.Service}
                      </C.CardBodyStatusText>
                    </C.CardBodyB>
                  </C.CardBody>
                  <C.CardFooter>
                    <C.CardHeaderInfo>
                      <C.CardHeaderNumber style={{color: '#000', marginBottom: '3px' }}>Ultima interação</C.CardHeaderNumber>
                      <C.CardHeaderNumber style={{ fontSize: '12px', fontWeight: 'normal', color: '#777', marginLeft: '3px'  }}>
                      <SupportAgentIcon style={{color: '#333',  marginRight: '7px'}} fontSize="medium"/>
                      { item.Article[item.Article.length - 1].Body.length > 210 ? item.Article[item.Article.length - 1].Body.substring(0,210)+' ...' : item.Article[item.Article.length - 1].Body }</C.CardHeaderNumber>
                    </C.CardHeaderInfo>
                  </C.CardFooter>
                </C.Card>
                ))}

            </C.Body>

            <G.Footer style={{ fontSize: '12px' }}>
              <p>Copyright <CopyrightIcon style={{ fontSize: '12px' }} /> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda.</p>
            </G.Footer>

            {loadingModal &&  
            <C.ModalContainer>
              <C.Logo src={"/loading.svg"} />
            </C.ModalContainer>
            }  
            {modal !== false &&
            <C.ModalContainer>
              <C.Options>
                <C.Button onClick={ShowReplyClick} style={{backgroundColor: selectedTicket.StateType === 'closed' ? '#2E8B57' : '#1E90FF'}}>{selectedTicket.StateType === 'closed' ? 'Reabrir' : 'Responder'}</C.Button>
                {selectedTicket.StateType !== 'closed' &&
                <C.Button onClick={ShowCloseClick} style={{backgroundColor: '#E95839'}}>Encerrar Chamado</C.Button>
                }
                  <C.CardHeaderLogo style={{ }}>
                     <HighlightOffIcon onClick={CloseModalClick} style={{color: '#333', cursor: 'pointer' }} fontSize="large"/>
                   </C.CardHeaderLogo>
              </C.Options>
              <C.Content>
              <C.Header id='view' style={{justifyContent: 'space-around', marginTop: '35px' }}>


                    <C.CardHeaderTitle style={{ fontSize: '16px', marginLeft: '10px'}}>
                   Chamado #{selectedTicket.TicketNumber} - {selectedTicket.Title}
                 </C.CardHeaderTitle>

              </C.Header>
              <C.ModalBody>
                <C.ModalDetails>

                      <C.CardBodyText style={{width: '180px', fontSize: '15px', borderBottom: selectedTicket.StateType === 'open' ? '5px solid #2E8B57' :
                                                                            selectedTicket.StateType === 'new' ? '5px solid #1E90FF' :
                                                                            selectedTicket.StateType === 'pending reminder' ? '5px solid #FCC703' : '5px solid #E95839',
                                                              backgroundColor: selectedTicket.State === 'Em Atendimento' ? '#F1FFF3' :
                                                              selectedTicket.State === 'Aguardando Cliente' ? '#FCF4DB' : '#f5f5f5',
                                                                               padding: '7px 12px' }}>
                    <strong>{selectedTicket.State==='new' ? 'Novo' : selectedTicket.State==='open' ? 'Aberto' :
                                                           selectedTicket.State==='closed successful' ? 'Fechado com exito' :
                                                           selectedTicket.State==='closed unsuccessful' ? 'Fechado sem exito' :
                                                          selectedTicket.State}</strong>

                    </C.CardBodyText>

                      <C.CardBodyStatusText >
                      <strong>Criado: </strong>{selectedTicket.Created}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Fila</strong>: {selectedTicket.Queue}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText style={{backgroundColor: selectedTicket.SLA === 'EM1-R' && 'EM2-R' ? '#E95839' : '#1E90FF', padding: '3px 7px', color: '#fff'  }}>
                      <strong>SLA: {selectedTicket.SLA}</strong>
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Tipo: </strong> {selectedTicket.Type}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Serviço: </strong> {selectedTicket.Service}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Criado por: </strong> {selectedTicket.CustomerUserID}
                      </C.CardBodyStatusText>
                      <C.CardBodyStatusText>
                      <strong>Responsável: </strong> {selectedTicket.Responsible}
                      </C.CardBodyStatusText>
                      <C.CardBodyText style={{borderLeft: '5px solid #2E8B57', width: '180px' }}>
                      Prazo para inicio: {selectedTicket.EscalationDestinationDate}
                      </C.CardBodyText>
                      <C.CardBodyText style={{borderLeft: '5px solid #E95839', width: '180px' }}>
                      Prazo para solução: {selectedTicket.SolutionTimeDestinationDate}
                      </C.CardBodyText>
                </C.ModalDetails>
                <C.ModalContentBody>
                {loading &&  
                <C.Logo src={"/loading.svg"} />
                }
                {showClose !== false &&
                <C.Article >
                  <C.ArticleHeader  style={{fontWeight: 'bold', height: '60px', backgroundColor: '#E95839'}}>
                      <C.CardHeaderInfo style={{color: '#fff', marginLeft: '10px'}}>
                      Atenção !
                      </C.CardHeaderInfo>
                  </C.ArticleHeader>
                  <C.ArticleBody  style={{padding: '20px'}}>
                      Este chamado será fechado e ficará com o estado "Fechado pelo Cliente", Deseja continuar ?
                  </C.ArticleBody>
                  <C.ArticleFooter style={{ justifyContent: 'center'}} >
                  <C.Button onClick={closeClick} style={{backgroundColor: '#2E8B57'}}> Sim</C.Button>
                  <C.Button onClick={ShowCloseClick} style={{backgroundColor: '#E95839'}}> Não</C.Button>
                  </C.ArticleFooter>
                </C.Article>}
                {showReply !== false &&
                <C.Article>
                  <C.ArticleHeader  style={{height: '60px', backgroundColor: selectedTicket.StateType === 'closed' ? '#BDF5D6' : '#C8E4FF'}}>
                    <ReplyIcon style={{marginLeft: '10px' }} fontSize='large'/>
                  <C.CardHeaderInfo style={{marginLeft: '10px'}}>
                      <C.CardHeaderNumber style={{fontSize: '14px', fontWeight: 'bold', color:'#000'}}>{selectedTicket.StateType === 'closed' ? 'Este chamado esta fechado! Envie uma interação para reabri-lo.' : 'Enviar Resposta:'}</C.CardHeaderNumber>
                      </C.CardHeaderInfo>
                  </C.ArticleHeader>
                  <C.ArticleBody  style={{ backgroundColor: '#F6FEFF', padding: '10px'}}>
                    <C.ArticleReply
                    value={content}
                    onChange={e=>setContent(e.target.value)}/>
                  </C.ArticleBody>
                  <C.ArticleFooter>
                  <C.Input  id='content'
                            placeholder='Escolha o arquivo'
                            onChange={e=>setSelectedFile(e.target.files[0])}
                            type="file"/>
                  <C.Button onClick={() => Validate()} style={{backgroundColor: selectedTicket.StateType === 'closed' ? '#2E8B57' : '#1E90FF'}}>Enviar</C.Button>
                  </C.ArticleFooter>
                </C.Article>}
                {selectedTicket.Article.map((article, key)=>(
                    <C.Article style={{borderLeft: article.SenderType !== 'customer' ? '3px solid #FFB400' : ''}} key={key}>
                    <C.ArticleHeader style={{justifyContent: 'space-between'}}>
                    <C.CardHeaderLogo style={{padding:'10px', borderRadius: '10px', backgroundColor: '#f1f1f1', marginLeft: '20px', marginRight: '-30px'}}>
                      {article.ArticleNumber}
                    </C.CardHeaderLogo>
                      <C.CardHeaderInfo >
                      <C.CardHeaderNumber>{article.From}</C.CardHeaderNumber>
                      <C.CardHeaderTitle >{ article.Subject}</C.CardHeaderTitle>
                      </C.CardHeaderInfo>
                      <C.CardHeaderNumber style={{marginRight: '10px', fontWeight: 'normal', fontSize: '12px'}} >
                      { article.CreateTime}
                      </C.CardHeaderNumber>
                    </C.ArticleHeader>
                    <C.ArticleBody>
                    <div dangerouslySetInnerHTML={{ __html: article.Body.replace(/\n/g, "<br />") }}></div>
                    </C.ArticleBody>
                   {article.Attachment &&
                    <C.ArticleFooter style={{flexDirection: 'column'}}>
                    {article.Attachment.map((attachment, key)=>(
                      <C.ArticleFilesArea >
                       <C.Button style={{backgroundColor: attachment.ContentType === 'application/pdf' ? '#E95839' :
                                                          attachment.ContentType === 'image/png' ? '#1E90FF' : '#FCC703',
                       width: '0px' ,minWidth: '70px' , color: '#000', fontWeight: 'normal', margin: '0px' }}>
                                                          {attachment.ContentType === 'application/pdf' ? 'PDF' :
                                                          attachment.ContentType === 'image/png' ? 'IMAGEM' : 'OUTROS'}
                        </C.Button>  
                      <C.Button style={{backgroundColor:'#34495e', minWidth: '220px' , color: '#ddd', fontWeight: 'normal' , marginLeft: '-10px' }}>
                        {attachment.Filename.length > 25 ? attachment.Filename.substring(0,25)+' ...' : attachment.Filename}</C.Button>
                      <C.Button style={{backgroundColor:'#2E8B57', width: '30px', marginLeft: '-20px'}} onClick={() => 
                        Download(attachment.Content, attachment.Filename, attachment.ContentType)}><FileDownloadIcon/></C.Button>
                      
                      </C.ArticleFilesArea>
                    ))}
                    </C.ArticleFooter>
                    }
                    </C.Article>
                  )).reverse()}

                 </C.ModalContentBody>
                 </C.ModalBody>
              </C.Content>

            </C.ModalContainer>

            }
             
        <Tooltip id="my-tooltip" place="bottom" />
        </C.Container>

    );
}
