import styled from 'styled-components';


/// GENERAL ///////////////////////////////////////////////////////////////////

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  width: 990px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 20px 5px 5px 5px;
  padding: 0px 15px;
  box-shadow: 0px 0px 3px #ccc;
`;

export const HeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;
  padding: 5px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 960px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 20px 5px 20px 5px;
  padding: 0px 15px;
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px;
`;

export const BoxHeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
`;

export const BoxHeaderOptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
`;

export const BoxBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px;

`;

export const BoxBodyScroll = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px;
  overflow-y: auto;
  overflow-x: none;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #C8E4FF;
  }
`;

export const BoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 10px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 990px;
  min-height: 30px;
  background-color: #fff;
  margin: 20px 5px 20px 5px;
  padding: 15px;
`;

export const OptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5px;
  border-radius: 7px;
  padding: 0px 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
`;

export const CardHeader = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px;
`;

export const CardBody = styled.div`
  display: flex;
  padding: 10px 10px;

`;

export const CardBodyScroll = styled.div`
  display: flex;
  padding: 10px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #333;
  }
`;


export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  padding: 10px;
`;

export const Loading = styled.img`
  width: auto;
  margin-top: 100px;
`;

export const Image = styled.img`
  width: auto;
`;



/// DATA TABLES ///////////////////////////////////////////////////////////////

export const TableArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 1px;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const FieldArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 40px;
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
  padding: 0px 5px;
  border-radius: 7px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 10px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
`;



/// TEXTS /////////////////////////////////////////////////////////////////////

export const MainTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 22px;
  font-weight: bold;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 18px;
  font-weight: bold;
`;

export const SmallTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 13px;
`;

export const DistacInfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
  font-weight: bold;
`;

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
`;



/// BUTTONS ///////////////////////////////////////////////////////////////////

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    filter: contrast(140%);
  }
`;

export const ButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    filter: brightness(1.1);
    font-weight: bold;
  }
`;

export const ButtonUnderLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 160px;
  height: 20px;
  color: #333;
  border-bottom: 2px dotted #333;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const SpecialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 160px;
  height: 25px;
  border-bottom: 2px solid #2E8B57;
  color: #000;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const IconButton = styled.div`
  padding: 7px;
  width: 25px;
  height: 20px;
  color: #fff;
  font-size: 12px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 3px 3px #ccc;
`;

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  width: 90px;
  height: 20px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  font-size: 13px;
  cursor: pointer;
  margin: 0px 5px;
`;

export const CircleButton = styled.div`
  padding: 7px;
  width: 20px;
  height: 20px;
  color: #000;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0px 1px 1px #000;
  background-color: #fff;
  &:hover {
    background-color: #BDF5D6;
  }
`;

export const BigButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  padding: 7px;
  width: 160px;
  height: 180px;
  border-radius: 10px;
  border: 1px solid #ffb400;
  cursor: pointer;
  margin: 30px;
  &:hover {
    background-color: #ffb400;
  }
`;



/// OTHERS ////////////////////////////////////////////////////////////////////

export const StateArea = styled.div`
  background-color: #f1f1f1;
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: space-around;
`;

export const SwitchArea = styled.div`
  background-color: #f1f1f1;
  height: 40px;
  border-radius: 10px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  width: 110px;
  justify-content: space-around;
  margin-right: 15px;
`;


export const SearchArea = styled.div`
  color: #777;
  height: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled.input`
  width: 200px;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  height: 35px;
  border-radius: 15px;
  padding: 0px 15px;
  font-family: inherit;
`;

export const Input = styled.input`
    font-size: 16px;
    color: #FFFFFF;
    background-color: rgba(52, 52, 52, 0.1);
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 15px;
`;

export const Input2 = styled.input`
  padding: 10px;
  border-radius: 7px;
  outline-color: ${(props) => props.OutlineColor};
  background-color: ${(props) => props.BackgroundColor};
  &:focus {
    background-color: ${(props) => props.FocusBackgroundColor};
  }
`;

export const FormInputArea = styled.div`
display: flex;
margin: 7px;
`;

export const FormInputLabel = styled.div`
align-items: center;
justify-content: start;
font-size: 11px;
font-weight: bold;
color: #000;
display: flex;
padding: 0px 10px;
width: 200px;
border-left: 3px solid #ccc;
height: 35px;
background-color: #f0f0f0;
border-radius: 3px 0px 0px 3px
`;

export const FormInput = styled.input`
font-size: 12px;
border: none;
width: 100%;
outline: none;
background-color: #f8f8f8;
padding: 0px 10px;
font-family: inherit;
height: 35px;
border-radius: 0px 3px 3px 0px;
margin-right: 10px;
`;